import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
// import * as jsPDF from 'jsPDf';
// import html2canvas from 'html2canvas';
// import * as firebase from "firebase/app";
import { GrsService } from "./../../services/grs.service";
@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit  , AfterViewInit{



  narrative_bullet_points: any = {

    data_governance: {
      NC01: "Impact of compliance and regulatory requirements",
      NC02: "Payment methods and amount of personal information collected and stored ect/Store personal Information",
      NG01: "Information Security Policy documentation, communication and training",
      NG02: "Incident management process",
      NG03: "Encryption policy",
      NG04: "Policies on destruction of paper and electronic information",
      NG05: "Assigning, changing and auditing user accounts",
      NG06: "Unauthorized software",
      NG07: "Utilization of background checks",
      NG08: "Disaster Recovery plans",
      NG09: "Ongoing Information Security Risk Assessments",
      NG10: "Outsourced services and vendor risk",
      NG11: "Cyber Risk Transfer",
    },
    physical_security: {
      NP01: "Physical security policies and assessment",
      NP02: "Access of vendors and visitors to the organization facilities ",
      NP03: "Data center access control ",
      NP04: "Back- up power and environmental controls ",
      NP05: "3rd Party data center or colocation utilization ",
    },
    external_security: {
      NE01: "External IP addresses utilized ",
      NE02: "Monitoring of traffic between internal and external networks and intrusion detection  ",
      NE03: "Utilization of E- Commerce ",
      NE04: "Remote access and authentication ",
      NE05: "Cloud services utilization and incident communication ",
      NE06: "Encryption for data transfers ",
      NE07: "External vulnerability scanning  ",
      NE08: "Penetration testing ",
    },
    internal_security: {
      NT01: "Internet circuits, firewalls and devices utilized",
      NT02: "Servers, workstations and laptops utilized",
      NT03: "Company owned mobile devices",
      NT04: "Personally owned mobile devices",
      NT05: "Network segmentation, management network, and DMZ’s",
      NT06: "Network based intrusion detection",
      NT07: "Network architecture and vendor network support",
      NT08: "Patch management process and documentation",
      NT09: "Vulnerability scans and resolution of issues identified",
      NT10: "Network  and server change process",
      NT11: "Laptop encryption",
      NT12: "Mobile device management",
      NT13: "Removable media",
      NT14: "Involvement in software development",
      NT15: "Hardened server and network standards",
      NT16: "Anti - Virus software and updates",
      NT17: "Network logging and monitoring standards",
      NT18: "Wireless network segmentation, firewalls and auditing",
      NT19: "User administrative rights",
    },

  }
   @Input() allAnswers: any
  bullet_points_set : boolean = false
  user_narative_bullet_points: any = {
    data_governance: {},
    physical_security: {},
    internal_security: {},
    external_security: {}
  }

  constructor(private grsService: GrsService) {

  }
  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.bullet_points_set = false
    let _th= this
    // firebase.auth().onAuthStateChanged(async (auth) => {
    //   // await this.getAllAnswers()
    // })
  }
  async getAllAnswers() {
    // try {
    //   let res: any = await this.grsService.getAllanswers();
    //   res = res.json();

    //   if (res.success) {
    //     this.allAnswers = res.answers
    //     console.log("All Answers are", this.allAnswers);
    //     this.setBulletPoints();
    //   }

    // } catch (error) {
    //   console.log(error, 'error');
    // }
  }

 

  setBulletPoints() {
    if (this.allAnswers) {     
      for (let key in this.allAnswers) {
        let options = this.allAnswers[key].options;
        for (let obj of options) {
          let tags = obj.option.tags;
          let question_type = obj.option.question_type
          if (tags && question_type) {
            this.user_narative_bullet_points[question_type][tags] = this.narrative_bullet_points[question_type][tags];
          }
        }
      }

      this.bullet_points_set = true
      console.log('user narrative bullet points are');
      for(let k of this.narrative_bullet_points){
        this.user_narative_bullet_points[k+"_arry"] = Object.values(this.user_narative_bullet_points[k])
      }     
      console.log(this.user_narative_bullet_points);      
      // let data_governance = user_narative_bullet_points['data_governance'];
      // strings_to_render = Object.values(data_governance);
    }
  }
  ngOnChanges() {
    // this.setBulletPoints()
  }
  //  generatePDF() {
  //   var data = document.getElementById('contentToConvert');
  //   console.log(data);
  //   // html2canvas(data).
  //   html2canvas(data).then(canvas => {

  //     // Few necessary setting options 
  //     console.log(canvas, 'canvas');

  //     // var imgWidth = 208;
  //     // var pageHeight = 295;
  //     // var imgHeight = canvas.height * imgWidth / canvas.width;
  //     // var heightLeft = imgHeight;

  //     // const contentDataURL = canvas.toDataURL('image/png')
  //     // let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF 
  //     // var position = 0;
  //     // pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
  //     // pdf.save('MYPdf.pdf'); // Generated PDF  

  //     // let imgWidth = 210;
  //     // let pageHeight = 295;
  //     // let imgHeight = canvas.height * imgWidth / canvas.width;
  //     // console.log(imgHeight, 'Image Height');
  //     // let heightLeft = imgHeight;

  //     // // enter code here
  //     // let imgData = canvas.toDataURL('image/png')
  //     // let doc = new jspdf('p', 'mm', 'a4');
  //     // let position = 0;
  //     // doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);      
  //     // heightLeft -= pageHeight;
  //     // console.log(heightLeft, 'heiht left');



  //     // // while (heightLeft >= 0) {
  //     // //   position = heightLeft - imgHeight;
  //     // //   doc.addPage();
  //     // //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     // //   heightLeft -= pageHeight;
  //     // // }


  //     // doc.save('file.pdf');


  //     let imgData: any = canvas.toDataURL('image/png');
  //     console.log(imgData, 'adata');

  //     /*
  //     Here are the numbers (paper width and height) that I found to work. 
  //     It still creates a little overlap part between the pages, but good enough for me.
  //     if you can find an official number from jsPDF, use them.
  //     */
  //     let imgWidth = 210;
  //     let pageHeight = 295;
  //     let imgHeight = canvas.height * imgWidth / canvas.width;
  //     console.log(imgHeight, 'img height');

  //     let heightLeft = imgHeight;

  //     let doc = new jsPDF('p', 'mm', "a4");
  //     let position = 0;

  //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, pageHeight);
  //     heightLeft -= pageHeight;

  //     // while (heightLeft >= 0) {
  //     //   position = heightLeft - imgHeight;
  //     //   doc.addPage();
  //     //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //     //   heightLeft -= pageHeight;
  //     // }
  //     doc.save('file.pdf');
  //   });


  // }

}
