import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mcq',
  templateUrl: './mcq.component.html',
  styleUrls: ['./mcq.component.scss']
})
export class McqComponent implements OnInit {
  colleges = [];
  selectedItems = [

  ];
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'option',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 4,
    allowSearchFilter: false
  };

  @Input() question_data: any = {
    answer: [],

  }
  @Input() index
  @Output() getNewQuestion: any = new EventEmitter<any>();
  @Output() postAnswer: any = new EventEmitter<any>();

  answerSubmited: boolean = true
  constructor() {
    this.question_data.answer = []
  }

  ngOnInit() { }

  ngOnChanges() {
    if (!this.question_data.answer || this.question_data.answer == "") {
      this.question_data.answer = []
    }
  }




  ansSumbited() {

    let selectedObjs = []

    if (this.question_data.answer && this.question_data.answer.length) {
      let emit = false
      for (let obj of this.question_data.options) {

        if (obj.next_question) {
          if (!emit) {
            // this.getNewQuestion.emit(obj)
          }
          emit = true
        }

        for (const id of this.question_data.answer) {
          if (obj.id == id.id) {
            selectedObjs.push(obj.id)
          }
        }
      }
    }



    let post_obj = {
      id: this.question_data.question.id,
      options_array: selectedObjs,
      question_type : this.question_data.question.question_type,
      user_answers  : this.question_data.user_answers,
      question_data : this.question_data
    }
  
    this.question_data.submitted = false;

    this.postAnswer.emit(post_obj)
  }

  onItemSelect(e) {
    this.question_data.submitted = true
  }

  onSelectAll(e) {
    this.question_data.submitted = true
  }

}
