import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() public show: boolean;
  @Output() closeModal = new EventEmitter<any>();
  @Input() message : any 
  constructor() { }

  ngOnInit() {
  }

  setTofalse() {
    this.show = false
    this.closeModal.emit(this.show);
  }

}
