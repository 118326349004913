import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-registry-profile',
  templateUrl: './registry-profile.component.html',
  styleUrls: ['./registry-profile.component.scss']
})
export class RegistryProfileComponent implements OnInit {
  @Input() crp_data: any;
  closedPanel: boolean = false
  constructor() { }
  ngOnInit() {
  }

  changeTextFormat(text) {
    text = text.toLowerCase();
   return this.capitalizeFirstLetter(text);
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}
