import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  constructor(private _authService: AuthService, private fb: FormBuilder) { }
  form: FormGroup;
  error_message: string;
  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }


  async forgotPassword() {

    if (this.form.invalid) {
      alert("Invald form!")
      return
    }

    try {

      let response: any = await this._authService.sendResetPasswordEmail(this.form.controls.email.value);
      console.log({ response })
      response = response.json();
      if (response.success) {
        window.alert(response.message || 'Password reset email sent, check your inbox.');
        window.location.href = "login";
      }
  
    } catch (error) {
      console.log({ error });
      let message = `Internal Server Error.`;
      if (error && error.json && error.json()) {
        let _error = error.json();
        message = _error.message ? _error.message : _error.error.message;
      }
      this.error_message = message;
    }

  }
}
