import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AirtableService } from "./../../services/airtable.service";
import { CONFIG } from "../../config/config";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  error_message: any = null
  signInDetails: any = {
    email: "",
    password: ""
  }
  loading: boolean = false
  type_password: any = 'password'
  crp_data: any
  footprint: any = window.localStorage.getItem("footprint") || null
  uid: any = window.localStorage.getItem("uid") || null
  constructor(private _authService: AuthService,
    private fb: FormBuilder,
    private airtable: AirtableService,
    private route: ActivatedRoute,
    private router: Router,) {

    if (CONFIG.jwt()) {
      if (this.footprint) {
        this.router.navigate(['home']);
      } else {
        this.router.navigate(['crp/false']);
      }
    }
  }

  ngOnInit() {

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    })

  }
  togglePassword() {
    this.type_password == 'password' ? this.type_password = 'text' : this.type_password = 'password'
  }




  async signInWithEmail(e) {
    e.preventDefault();
    this.loading = true
    let signInDetails = {
      email: this.form.controls.email.value,
      password: this.form.controls.password.value
    }
    try {

      let res: any = await this._authService.login(signInDetails)
      let response = res.json();
      if (!response.success) {
        this.error_message = response.message
      } else {
        window.localStorage.setItem("token", response.token);
        this.router.navigate(['/home'])
      }

    } catch (error) {
      let message = `We couldn't find your account.`;
      if (error && error.json && error.json()) {
        let _error = error.json();
        message = _error.error.message;
      }
      this.error_message = message;
      // alert(message)
    }
    this.loading = false
  }

}
