import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text-type',
  templateUrl: './text-type.component.html',
  styleUrls: ['./text-type.component.scss']
})
export class TextTypeComponent implements OnInit {
  @Input() question_data: any
  @Input() index: any
  entered_text: any

  @Output() getNewQuestion: any = new EventEmitter<any>();
  @Output() postAnswer: any = new EventEmitter<any>();
  answerSubmited  : boolean = true
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    if(!this.question_data.answer || this.question_data.answer == "" ){
      this.answerSubmited = false
    }
  }
  writting(e){
    this.answerSubmited = false
  }
  submitAnswer() {    


    let obj = {
      id: this.question_data.question.id,
      options_array: [],
      question_type : this.question_data.question.question_type,
      text : this.question_data.answer,
      user_answers  : this.question_data.user_answers,
      question_data : this.question_data
    }
    
    // if (this.question_data.user_answers) {
    //   this.question_data.user_answers.push(obj)
    // }


    this.answerSubmited = true
    // this.question_data.options[0].option = this.question_data.answer
    this.postAnswer.emit(obj)


  }
}
