import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, Inject } from '@angular/core';
import { GrsService } from "./../../../services/grs.service";
import { DOCUMENT } from '@angular/common';
import { AirtableService } from "./../../../services/airtable.service";
import { naratives } from "./../narative";
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-css-part',
  templateUrl: './css-part.component.html',
  styleUrls: ['./css-part.component.scss']
})
export class CssPartComponent implements OnInit {

  @Input() scoresGraphData: any = {}
  @Input() scores: any
  @Input() set_classnames: any
  @Input() risk_texts: any
  @Input() footprint: any
  @Output() calCss: any = new EventEmitter<any>()

  naratives: any = naratives
  form: FormGroup;

  cssSection = {
    type: 'css',
    title: 'Company Information',
    total_questions: null,
    per_page: 4,
    step: 1,
    questionsArry: [],
    arrow_open: false,
    section_enabled: false,
    total_pages: 0,
    valiationError: false,
    is_final_step: false
  }
  new_grs_steps: any = {
    "grs": 1,
    "css": 1,
    "data_governance": 1,
    "physical_security": 1,
    "internal_security": 1,
    "external_security": 1
  };

  per_page: any = 4

  navigations: any = {
    css: {
      total_questions: null,
      per_page: this.per_page
    },

  }
  type: any = 'css'
  questionsArry: any

  loading: boolean = false;

  openInput: boolean = false;
  notEnteredDays: boolean = true;
  no_of_days_question: any;
  specific_no_days: any = null;
  choosen_option_first: any;

  show_more_text: any;
  question_id: any;

  constructor(private grsService: GrsService,
    private airtable: AirtableService,
    private fb: FormBuilder,
    @Inject(DOCUMENT) document) {
    // this.getQuestions(this.cssSection)
    this.getConfigDetails();


    this.form = this.fb.group({
      specific_no_days: [null, Validators.compose([
        Validators.required,
        Validators.max(180),
        Validators.min(31)
      ])],
    });
  }

  ngOnInit() { }
  ngOnChanges() { }

  async getQuestions(obj) {
    this.loading = true
    try {
      let res: any = await this.grsService.getQuestionType(obj.step - 1, obj.per_page, obj.type, null);
      console.log("Response in Single Type Questions");
      console.log(res);
      if (res.success) {

        for (const o of res.questions) {
          if (o.user_answers[0]) {
            o.answer = o.user_answers[0].option
          }
        }

        this.questionsArry = res.questions
        this.navigations[this.type].total_questions = res.total_count

        this.cssSection.total_questions = res.total_count
        this.cssSection.per_page = obj.per_page
        this.cssSection.questionsArry = res.questions
        this.cssSection.total_pages = Math.ceil(this.cssSection.total_questions / obj.per_page);

      }


    } catch (error) {
      console.log(error, 'error in code');
    }
    this.loading = false
  }

  async postConfig() {
    try {
      let obj = {
        "config": this.new_grs_steps
      }
      let res: any = await this.grsService.postConfig(obj);
      res = res.json();
      console.log("post config details");
      console.log(res);

    } catch (error) {
      console.log(error, 'post req aee');
    }
  }


  async postAnswerToDB(answer_obj) {
    try {

      let obj: any = {
        question_id: answer_obj.id,
        options_arr: answer_obj.options_array
      }

      if (answer_obj.is_text_input_allowed) {
        this.openInput = true;
        this.question_id = obj.question_id;
        this.no_of_days_question = answer_obj;
        return;
      }

      let is_update = false;
      if (answer_obj.user_answers && answer_obj.user_answers.length) {
        console.log('This is an update question');
        is_update = true;
      }

      if (!is_update) {
        let res: any = await this.grsService.setAnswerOfUserQuestion(obj);
        console.log("Answer set");
        let response: any = res.json();
        console.log(response);


        for (let small_obj of this.cssSection.questionsArry) {
          if (small_obj.question.id == obj.question_id) {
            small_obj.user_answers.push(obj);
          }
        }
        return;
      }


      /// Here below updating Answer part begins ///////

      let update_obj: any = {
        question_id: answer_obj.id,
        new_options: answer_obj.options_array,
      }


      let res: any = await this.grsService.updateAnswerOfUserQuestion(update_obj);
      console.log("Update Answer is set");
      console.log(res.json());

      
    } catch (error) {
      console.log("error in set answer");
      console.log(error);
    }


  }


  async goTONextStep(c) {

    // c is entire obj inside the allSections Array;

    for (let obj of this.questionsArry) {
      // if any one question not answered don't let them go
      let is_array = Array.isArray(obj.user_answers)

      if (is_array) {
        if (!obj.user_answers.length) {
          console.log("User not allowed to next step of ", c.type);
          c.valiationError = true
          return
        }
      }


      if (!obj.user_answers) {
        console.log("User not allowed to next step of ", c.type);
        c.valiationError = true
        return
      }

    }

    c.valiationError = false
    if (c.step < c.total_pages) {
      c.step = c.step + 1
      if (c.step > this.new_grs_steps[c.type]) {
        this.new_grs_steps[c.type] = c.step
      }
      if (c.step == 4) {
        this.cssSection.is_final_step = true;
      } else {
        this.cssSection.is_final_step = false
      }
      await this.postConfig();
      await this.getQuestions(c)


    } else {
      this.calCss.emit(true)
      this.cssSection.is_final_step = true;
      console.log('User finshes the all steps in this section');
    }

  }


  // using //
  async getConfigDetails() {
    try {

      let res: any = await this.grsService.getConfigDetails();

      let response: any = res.json();
      // console.log("config details");
      // console.log(response);
      if (response.success) {
        if (response.config) {
          this.new_grs_steps.grs = parseFloat(response.config.grs) || 0;
          this.new_grs_steps.css = parseFloat(response.config.css) || 0;
          this.new_grs_steps.data_governance = parseFloat(response.config.data_governance) || 0;
          this.new_grs_steps.internal_security = parseFloat(response.config.internal_security) || 0;
          this.new_grs_steps.external_security = parseFloat(response.config.external_security) || 0;
          this.new_grs_steps.physical_security = parseFloat(response.config.physical_security) || 0;
          this.cssSection.step = parseFloat(response.config.css) || 1;

        }
      }
    } catch (error) {
      console.log(error, 'eror');
    }


    await this.grsService.getAllTotalQuestions()
    await this.getQuestions(this.cssSection);

    if (this.cssSection.step >= this.cssSection.total_pages) {
      this.cssSection.is_final_step = true;
    }

  }




  async goToThatStep(stepNumber, c) {

    if (stepNumber > this.new_grs_steps[c.type]) {
      console.log("User not allowed to next step of ", c.type);
      return
    }
    if (stepNumber <= c.total_pages) {
      c.step = stepNumber
      await this.getQuestions(c)
      this.cssSection.is_final_step = false
      // this.setAllAnswersToUi()
    } else {
      this.cssSection.is_final_step = true;
      console.log('User finshes the all steps in this section');
    }

  }



  setShowMore(risk_texts) {
    if (risk_texts == "composite_GRS") {
      this.show_more_text = this.naratives['composite_GRS']['info'];
      return
    }
    this.show_more_text = this.naratives[this.footprint][risk_texts];
  }




  final_saved_string: any;

  setValueForMorethanDays(e) {
    if (e.target.value && e.target.value != "") {
      let value_for_qus = Number(e.target.value) * (-0.0017)
      this.notEnteredDays = false
    } else {
      this.notEnteredDays = true
    }
  }

  saveDays(e) {
    if (e) {
      e.preventDefault();
    }

    this.openInput = false
    this.specific_no_days = this.form.value.specific_no_days + " Days";
    this.no_of_days_question.is_text_input_allowed = false;
    this.no_of_days_question.text = this.specific_no_days;
    console.log(this.specific_no_days, "no of days");
    this.postAnswerToDB(this.no_of_days_question);

    this.form = this.fb.group({
      specific_no_days: [null, Validators.compose([
        Validators.required,
        Validators.max(180),
        Validators.min(31)
      ])],
    });


  }

  closeInpModal() {
    this.no_of_days_question = null;
    this.openInput = false;
    this.specific_no_days = null;
    this.form = this.fb.group({
      specific_no_days: [null, Validators.compose([
        Validators.required,
        Validators.max(180),
        Validators.min(31)
      ])],
    });
    for (let small_obj of this.cssSection.questionsArry) {
      if (small_obj.question.id == this.question_id) {
        small_obj.answer = null;
      }
    }
  }


}
