import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
    private token: any;
    private role: any;

    constructor(private router: Router) { }

    canActivate() {
        if (this.isLoggedIn()) {          
            return true;                  
        } else {                                   
            this.router.navigate(['/login']);
            return false;
        }
    }

    isLoggedIn(): boolean {
        this.token = window.localStorage.getItem('token');
        if (!this.token) {
            return false;
        } else {
            return true
              
        }
    }
}