
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';;
import { Headers, Http } from '@angular/http';
import { CONFIG } from './../config/config';

@Injectable()
export class AuthService {

  headers: HttpHeaders;

  constructor(private _http: HttpClient, private http: Http) { }

  login(data) {
    let headers = new Headers({
      'Content-Type': 'application/json'
    });
    let url = `${CONFIG.API_URL}/login`;
    return this.http.post(url, data, { headers: headers }).toPromise();
  }

  register(data) {
    let headers = new Headers({
      'Content-Type': 'application/json'
    });
    let url = `${CONFIG.API_URL}/register`
    return this.http.post(url, data, { headers: headers }).toPromise();
  }

  sendResetPasswordEmail(email) {
    let headers = new Headers({
      'Content-Type': 'application/json'
    });
    let url = `${CONFIG.API_URL}/forgot/password`
    return this.http.post(url, { email }, { headers: headers }).toPromise();
  }

  accountPasswordReset(password, emailHash) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    let url = `${CONFIG.API_URL}/reset/password`
    return this._http.post(url, { password, emailHash }, { headers: headers }).toPromise();
  }

  profile() {
    let headers = new Headers({
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': window.localStorage.getItem('token'),
    });
    let url = `${CONFIG.API_URL}/profile`
    return this.http.get(url, { headers: headers }).toPromise();
  }

  cisValues(data) {
    let headers = new Headers({
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': window.localStorage.getItem('token'),
    });
    let url = `${CONFIG.API_URL}/cis/metadata?`
    for (let key in data) {
      if (data[key]) {
        url += `${key}=${data[key]}&`
      }
    }
    return this.http.get(url, { headers: headers }).toPromise();
  }

  commonData() {
    let headers = new Headers({
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': window.localStorage.getItem('token'),
    });
    let url = `${CONFIG.API_URL}/commonData`
    return this.http.get(url, { headers: headers }).toPromise();
  }

  getNaicsData(countyId, ClassId) {
    let Countries = countyId
    let classOfTrades = ClassId
    let headers = new Headers({
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': window.localStorage.getItem('token'),
    });
    let url = `${CONFIG.API_URL}/getNacisCode?countries=${Countries}&class_of_trades=${classOfTrades}`
    return this.http.get(url, { headers: headers }).toPromise();
  }

}