import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// import { ChartsModule } from 'ng2-charts';
// Angular Animations Module
// import { HttpModule } from '@angular/http';

// import { environment } from './../environments/environment';

// import { AngularFireModule } from 'angularfire2';
// import { AngularFirestoreModule } from 'angularfire2/firestore';
// import { AngularFireAuthModule } from 'angularfire2/auth';


//// Modules //////
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module';
import { MatSelectModule, MatMenuModule, MatFormFieldModule, MatInputModule, MatIconModule, MatFormFieldControl, MatTooltipModule } from '@angular/material';
import { ChartistModule } from 'ng-chartist';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ScrollingModule } from '@angular/cdk/scrolling'
import { AuthGuardService } from "./gaurds/auth.gaurd";

//// Services //////
import { AirtableService } from './services/airtable.service'
import { AuthService } from './services/auth.service';
import { GrsService } from './services/grs.service';

//// Components //////
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { FooterComponent } from './components/footer/footer.component';
import { CrpComponent } from './components/crp/crp.component';
import { TestingComponent } from './components/testing/testing.component';
import { NotificationComponent } from './components/notification/notification.component';
import { GrsPartComponent } from './components/grs-part/grs-part.component';
import { OcqComponent } from './components/grs-part/ocq/ocq.component';
import { TextTypeComponent } from './components/grs-part/text-type/text-type.component';
import { NavigationComponent } from './components/grs-part/navigation/navigation.component';
import { McqComponent } from './components/grs-part/mcq/mcq.component';
import { GrsReportComponent } from './components/grs-report/grs-report.component';
import { RegistryProfileComponent } from "./components/home/registry-profile/registry-profile.component";
import { ScoresComponent } from "./components/home/scores/scores.component";
import { ImpactScoresComponent } from "./components/home/impact-scores/impact-scores.component";
import { LoadingComponent } from './components/loading/loading.component';
import { CssPartComponent } from './components/home/css-part/css-part.component';
import { OcqDropdownComponent } from './components/grs-part/ocq-dropdown/ocq-dropdown.component';
import { GraphComponent } from './components/common/graph/graph.component';
import { BriefReportComponent } from './components/brief-report/brief-report.component';
import { DetailReportComponent } from './components/detail-report/detail-report.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    ForgotpasswordComponent,
    FooterComponent,
    CrpComponent,
    TestingComponent,
    NotificationComponent,
    GrsPartComponent,
    OcqComponent,
    TextTypeComponent,
    NavigationComponent,
    McqComponent,
    GrsReportComponent,
    RegistryProfileComponent,
    ScoresComponent,
    ImpactScoresComponent,
    LoadingComponent,
    CssPartComponent,
    OcqDropdownComponent,
    GraphComponent,
    BriefReportComponent,
    DetailReportComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    // AngularFireAuthModule,
    NgxPaginationModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatMenuModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ChartistModule,
    ScrollingModule,
    HttpModule,
    PaginationModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  exports: [MatSelectModule, MatMenuModule, MatInputModule, MatFormFieldModule, MatTooltipModule],
  providers: [AirtableService, AuthService, AuthGuardService, GrsService],
  bootstrap: [AppComponent],
})
export class AppModule { }
