import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-scores',
  templateUrl: './scores.component.html',
  styleUrls: ['./scores.component.scss']
})
export class ScoresComponent implements OnInit {

  @Input() scoresGraphData: any;
  // @Input() scores: any = {
  //   css: 0,
  //   grs: 0,
  //   cis: 0,
  //   composite_grs_score: 0
  // }

  scores: any = {
    css: 0,
    grs: 0,
    cis: 0,
    composite_grs_score: 0
  }

  allGraphs: any = []
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

    if (this.scoresGraphData) {
      localStorage.setItem('scoresGraphData', JSON.stringify(this.scoresGraphData))
      // console.log("Final Graphs");
      this.allGraphs = Object.values(this.scoresGraphData);
      if (this.scoresGraphData.composite_grs) {
        let score = this.scoresGraphData.composite_grs.score;
        if (score) {
          let i = this.allGraphs.findIndex(o => o.data_type == 'grs');
          this.allGraphs.splice(i, 1);
        }else{
          let i = this.allGraphs.findIndex(o => o.data_type == 'composite_grs');
          this.allGraphs.splice(i, 1);
        }
      }
      // console.log(this.allGraphs);
    }

  }

}
