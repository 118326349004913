import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth.service';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Route } from '@angular/router';

export interface IPasswordFields {
  password: 'password' | 'text',
  confirmPassword: 'password' | 'text'
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }
  form: FormGroup;
  error_message: any = null
  loading: boolean = false;
  type_password: any = 'password'

  passwordInputType: IPasswordFields = {
    password: 'password',
    confirmPassword: 'password'
  };
  emailHash: string = "";

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {
      validators: this.passwordMatch('password', 'confirmPassword')
    });
  }

  public togglePassword(type: keyof IPasswordFields): void {
    this.passwordInputType[type] = this.passwordInputType[type] === 'password' ? 'text' : 'password';
  }

  passwordMatch(password: string, confirmPassword: string): ValidatorFn {
    return (formGroup: AbstractControl): { [key: string]: any } | null => {
      const passwordControl = formGroup.get(password);
      const confirmPasswordControl = formGroup.get(confirmPassword);
      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }
      if (
        confirmPasswordControl.errors &&
        !confirmPasswordControl.errors['passwordMismatch']
      ) {
        return null;
      }
      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true }
      } else {
        confirmPasswordControl.setErrors(null);
        return null;
      }
    };
  }

  async submitForm() {
    if (this.form.invalid) {
      alert("Invald form!")
      return
    }

    this.emailHash = this.route.snapshot.paramMap.get('uniqId') as string;

    try {

      let payload: any = this.form.value;
      let response: any = await this._authService.accountPasswordReset(payload.confirmPassword, this.emailHash);
      console.log({ response })
      if (response.json && response.json()) {
        response = response.json();
      }
      if (response.success) {
        window.alert(response.message || 'Password reset successfully.');
        window.location.href = "login";
      }


    } catch (error) {
      console.log({ error: error.error });
      let message = `Internal Server Error.`;
      if (error && error.json && error.json()) {
        let _error = error.json();
        message = _error.message ? _error.message : _error.error.message;
      }
      if (error.error && error.error.message) {
        message = error.error.message
      }
      this.error_message = message;
    }

  }

}

