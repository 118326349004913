import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, Inject } from '@angular/core';
import { GrsService } from "./../../services/grs.service";
// import * as firebase from "firebase/app";
import { DOCUMENT } from '@angular/common';
import { AirtableService } from "./../../services/airtable.service";
import { AuthService } from "./../../services/auth.service";
@Component({
  selector: 'app-grs-part',
  templateUrl: './grs-part.component.html',
  styleUrls: ['./grs-part.component.scss']
})
export class GrsPartComponent implements OnInit, AfterViewInit {

  @Input() crp_data: any;
  @Input() grs_step_completed_now: any
  @Output() needCRPUpdateCRP: any = new EventEmitter<any>();
  @Output() nowShowScore: any = new EventEmitter<any>();
  @ViewChildren('accordian') accordians: QueryList<ElementRef>;


  openModal: boolean = false;
  loading: boolean = false;


  getScoresDetails: any;
  total_questions: any;
  allAnswers: any;



  page: number = 0;
  per_page: number = 7;
  type: any = 'grs';
  question_id: any;


  element: HTMLElement;
  valiationError: any;
  is_finalstep_grs: any;

  scores: any = {
    css: 0,
    cis: 0,
    grs: 0,
  }


  get_cis_score: any = {
    no_of_employees: "",
    business_class: "",
    business_subclass: "",
    regulatory_category: "",
    NAICS_NACE_code: "",
    annual_revenue: ""
  }


  questionsArr: any[]
  employes_count: any = [
    { "Employee Number": "0 - 50", "Risk Metric": "0" },
    { "Employee Number": "50 - 200", "Risk Metric": "-0.005" },
    { "Employee Number": "200 - 500", "Risk Metric": "-0.007" },
    { "Employee Number": "500 - 1000", "Risk Metric": "-0.009" },
    { "Employee Number": "1000 - 5000", "Risk Metric": "-0.01" },
    { "Employee Number": "5000 - 10,000", "Risk Metric": "-0.02" },
    { "Employee Number": "10,000 - 25,000", "Risk Metric": "-0.025" },
    { "Employee Number": "25,000 +", "Risk Metric": "-0.03" },
  ]
  revenues: any = [
    { "Revenue Number": "Less than 1.0", "Risk Metric": "0.000", "value": "0.790" },
    { "Revenue Number": "1.0 - 4.99", "Risk Metric": "-0.004", "value": "0.790" },
    { "Revenue Number": "5.0 - 19.99", "Risk Metric": "-0.007", "value": "0.720" },
    { "Revenue Number": "20.0 - 49.99", "Risk Metric": "-0.01", "value": "0.660" },
    { "Revenue Number": "50.0 - 99.99", "Risk Metric": "-0.015", "value": "0.600" },
    { "Revenue Number": "100.0 - 499.99", "Risk Metric": "-0.02", "value": "0.600" },
    { "Revenue Number": "500.0 - 999.99", "Risk Metric": "-0.02", "value": "0.545" },
    { "Revenue Number": "1,000.0 - 4,999.99", "Risk Metric": "-0.02", "value": "0.545" },
    { "Revenue Number": "5,000.0 or more", "Risk Metric": "-0.02", "value": "0.500" }
  ];


  allSectionTextInfo: any = [
    {
      type: 'grs',
      text_info: 'Administrative Governance includes thefundamentalmanaging policies, standardsand procedures that ensure adequate risk-aware controls are designed and implemented by the organization.  The data governance processincludesdata risk monitoring, breach notification,includingthe strategic and tacticalresponsibilities in the creation, handling, classification, archiving, and deletion of data.',
      text_info_two: 'The Administrative Governance rating is compiled from answers to questions about the organization, its policies, processes, procedures, training and overall data governance.  Governance provides the foundation for sustainable information security strategies and compliance.  For many organizations, governance is the weakest area of compliance and information security. Based on responses to the questions, the following areas of concern should be explored and understood.',
      text_info_heading: null
    },
    {
      type: 'data_governance',
      text_info: 'Administrative Governance includes thefundamentalmanaging policies, standardsand procedures that ensure adequate risk-aware controls are designed and implemented by the organization.  The data governance processincludesdata risk monitoring, breach notification,includingthe strategic and tacticalresponsibilities in the creation, handling, classification, archiving, and deletion of data.',
      text_info_two: 'The Administrative Governance rating is compiled from answers to questions about the organization, its policies, processes, procedures, training and overall data governance.  Governance provides the foundation for sustainable information security strategies and compliance.  For many organizations, governance is the weakest area of compliance and information security. Based on responses to the questions, the following areas of concern should be explored and understood.',
      text_info_heading: "Data Governance"
    },
    {
      type: 'physical_security',
      text_info: "PhysicalSecurity represents the physical infrastructure that encompasssite security for all aspects of modern business and ensures thatdata security is monitored, sustainable,and resilient. Physical security controls are used to detect and prevent unauthorized access to digital assets and security resources.",
      text_info_two: "Physical Security includes the systems implemented to detect, prevent and control unauthorized physical access to the organization and its information resources.   Physical security also includes power supply and environmental monitoring and management processes.  Based on responses to the questions, the following areas of concern should be explored and understood",
      text_info_heading: "Physical Security"
    },
    {
      type: 'internal_security',
      text_info: "Internal Network Security consists of the policies and practices adopted to prevent and monitor unauthorized access, misuse, modification, or denial of a computer network and network-accessible resources.  Network security involves the authorization of access to data in a network, and covers a variety of computer networks, both public and private, which are managed by a network administrator.  It does as its title explains: It secures the network, as well as protecting and overseeing network operations. ",
      text_info_two: "Internal Network Security involves the inherent risks and vulnerabilities associated with the organization’s network architecture and network resources that are used in everyday jobs.  It also includes the oversight of network topology, policies, standards and proceduresthat provide operational assurance.   Internal Network Security addressand maintain network management frameworks thatmeet regulatory data security requirements and support network objectives. Internal networks are usually private, such as conducting transactions and communications among internal operators, branch offices, departmentsandbusiness associates. Based on responses to the questions, the following areas of concern should be explored and understood. ",
      text_info_heading: "Internal Network"
    },
    {
      type: 'external_security',
      text_info: "External Network Security incorporates theorganization’s egress/ingress data and establish rules for computer network access, determines how policies are enforced and lays out some of the basic architecture of the organization’s network security environment.  External Network Security covers vulnerabilities that pose a risk to unauthorized information access, disclosure, alteration or destruction of the organization’s publicly accessible information resources andincluding network access to private, public and hybridcloud services. ",
      text_info_two: "External Network Security includes risks associated with the technical resources, systems and processes utilized, but not owned by the organization, and are also accessible by the public, people not directly under the organization’s control.   As organizations utilize cloud-based resources and capabilities that add efficiencies and cost savings, potential risk and compliance requirements increase.  Policies may be expressed as a set of instructions that could be understood by special purpose network hardware dedicated for securing the network. In addition, External Network Security should dictate a hierarchy of access permissions; that is, grant users access only to what is necessary for the completion of their work. Based on responses to the questions, the following areas of concern should be explored and understood.",
      text_info_heading: "External Network"
    }
  ]


  allSections: any = [
    {
      type: 'grs',
      title: 'Company Information',
      total_questions: null,
      per_page: 7,
      step: 1,
      questionsArry: [],
      arrow_open: false,
      section_enabled: false,
      total_pages: 0,
    },
    {
      type: 'data_governance',
      title: 'Data Governance',
      total_questions: null,
      per_page: 7,
      step: 1,
      questionsArry: [],
      arrow_open: false,
      section_enabled: false,
      total_pages: 0
    },
    {
      type: 'physical_security',
      title: 'Physical Security',
      total_questions: null,
      per_page: 5,
      step: 1,
      questionsArry: [],
      arrow_open: false,
      section_enabled: false,
      total_pages: 0
    },
    {
      type: 'internal_security',
      title: 'Internal Network',
      total_questions: null,
      per_page: 12,
      step: 1,
      questionsArry: [],
      arrow_open: false,
      section_enabled: false,
      total_pages: 0
    },
    {
      type: 'external_security',
      title: 'External Network',
      total_questions: null,
      per_page: 5,
      step: 1,
      questionsArry: [],
      arrow_open: false,
      section_enabled: false,
      total_pages: 0
    }
  ]

  navigations: any = {
    grs: {
      total_questions: null,
      per_page: this.per_page
    },
    data_governance: {
      total_questions: null,
      per_page: this.per_page
    },
    physical_security: {
      total_questions: null,
      per_page: '0'
    },
    internal_security: {
      total_questions: null,
      per_page: this.per_page
    },
    external_security: {
      total_questions: null,
      per_page: '0'
    }
  }

  counter_obj: any = {
    "grs": 0,
    "data_governance": 0,
    "physical_security": 0,
    "internal_security": 0,
    "external_security": 0
  }

  new_grs_steps: any = {
    "grs": 0,
    "data_governance": 0,
    "physical_security": 0,
    "internal_security": 0,
    "external_security": 0
  };

  questions_index = {
    "grs": 0,
    'data_governance': 1,
    'physical_security': 2,
    'internal_security': 3,
    'external_security': 4
  };




  constructor(private grsService: GrsService,
    private airtable: AirtableService,
    private authService: AuthService,
    @Inject(DOCUMENT) document) { }

  ngOnInit() { }

  //// migration Begins Here ////
  // LOL ///
  async ngAfterViewInit() {
    await this.getConfigDetails();
  }


  // using //
  async getConfigDetails() {
    try {

      let res: any = await this.grsService.getConfigDetails();
      let response: any = res.json();
      console.log("config details");
      console.log(response);
      if (response.success) {
        if (response.config) {
          this.new_grs_steps.grs = parseFloat(response.config.grs) || 0;
          this.new_grs_steps.css = parseFloat(response.config.css) || 0;
          this.new_grs_steps.data_governance = parseFloat(response.config.data_governance) || 0;
          this.new_grs_steps.internal_security = parseFloat(response.config.internal_security) || 0;
          this.new_grs_steps.external_security = parseFloat(response.config.external_security) || 0;
          this.new_grs_steps.physical_security = parseFloat(response.config.physical_security) || 0;

          for (const obj of this.allSections) {
            if (this.new_grs_steps[obj.type]) {
              obj.step = this.new_grs_steps[obj.type];
            }
          }
        }
      }

    } catch (error) {
      console.log(error, 'eror');
    }

    await this.componentDidMount();

  }



  async componentDidMount() {
    this.loading = true
    await this.grsService.getAllTotalQuestions();
    this.loading = false
    this.allSections[0].section_enabled = true
    await this.getQuestions(this.allSections[0])
    await this.setAllAnswersToUi();
    await this.getUserEligibitySections();
  }



  async getQuestions(obj) {

    let active_step = 0;
    if (obj.type == 'grs') {
      active_step = obj.step - 2
    } else {
      active_step = obj.step - 1
    }

    try {
      let res: any = await this.grsService.getQuestionType(active_step, obj.per_page, obj.type, null);

      console.log("Response in Single Type Questions");
      console.log(res);

      if (res.success) {

        this.questionsArr = res.questions
        this.navigations[this.type].total_questions = res.total_count;

        for (let single_obj of this.allSections) {
          if (single_obj.type == obj.type) {
            single_obj.total_questions = res.total_count
            single_obj.per_page = obj.per_page
            single_obj.questionsArry = JSON.parse(JSON.stringify(res.questions));
            if (single_obj.type == "grs") {
              single_obj.total_pages = Math.ceil(single_obj.total_questions / obj.per_page) + 1;
            } else {
              single_obj.total_pages = Math.ceil(single_obj.total_questions / obj.per_page);
            }
          }
        }

        console.log(this.allSections, 'all Sections');
      }
    } catch (error) {
      console.log(error, 'get single qus error in code');
    }
    this.loading = false
    return true;
  }






  async postConfig() {
    try {
      let obj = {
        "config": this.new_grs_steps
      }
      let res: any = await this.grsService.postConfig(obj);
      res = res.json();
      // console.log("post config details");
      // console.log(res);
    } catch (error) {
      console.log(error, 'post req aee');
    }
  }


  async assainAnswers(answer_obj, obj) {
    try {
      let found, o, i = 0;


      let questions: any = this.allSections[this.questions_index[answer_obj.question_type]].questionsArry;


      for (let single_obj of this.allSections) {
        if (single_obj.type == answer_obj.question_type) {

          for (let small_obj of single_obj.questionsArry) {

            if (small_obj.question.id == obj.question_id) {

              small_obj.user_answers.push(answer_obj);

              if (small_obj.question.answer_type == "ocq") {
                if (small_obj.question.is_checkbox) {
                  small_obj.answer = obj.options_arr[0];
                }
              }


              if (small_obj.question.answer_type == "text") {
                small_obj.answer = answer_obj.text;
              }

              if (small_obj.question.answer_type == "mcq") {
                console.log("mcq is creating");

                let all_answers = answer_obj.options_array;
                let ids = []
                for (let opt_object of all_answers) {
                  for (let ob of small_obj.options) {
                    if (ob.id == opt_object) {
                      ob.checked = true
                      let ids_obj = {
                        id: ob.option_id,
                        option: ob.option
                      }
                      ids.push(ids_obj);
                    }
                  }
                }
                small_obj.answer = ids
                small_obj.submitted = false
              }

            }

            if (obj.options_arr && obj.options_arr[0]) {
              found = small_obj.options.findIndex(o => o.id == obj.options_arr[0]);

              if (found != null && found != undefined && found != -1) {
                o = small_obj.options[found];

                if (o.next_question) {
                  await this.getNewQuestionInset(o, i, questions);
                }
              }
              i++;
            }
          }
        }
      }

    } catch (error) {
      console.log("error occured when applying answers");
      console.log(error);
    }
  }

  async postAnswerToDB(answer_obj) {
    try {
      let obj: any = {
        question_id: answer_obj.id,
        options_arr: answer_obj.options_array,
      }

      if (answer_obj.text) {
        obj.text = answer_obj.text;
        delete obj.options_arr;
      }



      let is_update = false;
      if (answer_obj.user_answers && answer_obj.user_answers.length) {
        console.log('This is an update question');
        is_update = true;
      }

      if (!is_update) {

        let res: any = await this.grsService.setAnswerOfUserQuestion(obj);
        console.log("Answer set");
        console.log(res.json());
        let found, o, i = 0;

        await this.assainAnswers(answer_obj, obj);

        // let questions: any = this.allSections[this.questions_index[answer_obj.question_type]].questionsArry;


        // for (let single_obj of this.allSections) {
        //   if (single_obj.type == answer_obj.question_type) {

        //     for (let small_obj of single_obj.questionsArry) {

        //       if (small_obj.question.id == obj.question_id) {

        //         small_obj.user_answers.push(answer_obj);

        //         if (small_obj.question.answer_type == "ocq") {
        //           if (small_obj.question.is_checkbox) {
        //             small_obj.answer = obj.options_arr[0];
        //           }
        //         }


        //         if (small_obj.question.answer_type == "text") {
        //           small_obj.answer = answer_obj.text;
        //         }

        //         if (small_obj.question.answer_type == "mcq") {
        //           console.log("mcq is creating");

        //           let all_answers = answer_obj.options_array;
        //           let ids = []
        //           for (let opt_object of all_answers) {
        //             for (let ob of small_obj.options) {
        //               if (ob.id == opt_object) {
        //                 ob.checked = true
        //                 let ids_obj = {
        //                   id: ob.option_id,
        //                   option: ob.option
        //                 }
        //                 ids.push(ids_obj);
        //               }
        //             }
        //           }
        //           small_obj.answer = ids
        //           small_obj.submitted = false
        //         }

        //       }

        //       if (obj.options_arr && obj.options_arr[0]) {
        //         found = small_obj.options.findIndex(o => o.id == obj.options_arr[0]);

        //         if (found != null && found != undefined && found != -1) {
        //           o = small_obj.options[found];

        //           if (o.next_question) {
        //             await this.getNewQuestionInset(o, i, questions);
        //           }
        //         }
        //         i++;
        //       }
        //     }
        //   }
        // }

        return;
      }


      /// Here below updating Answer part begins ///////

      let update_obj: any = {
        question_id: answer_obj.id,
        new_options: answer_obj.options_array
      }


      let questions: any = this.allSections[this.questions_index[answer_obj.question_type]].questionsArry;

      let questions_answers = answer_obj.user_answers
      let existing_options = [];

      let next_question = null, i;


      for (i of questions_answers) {
        for (let oo of answer_obj.question_data.options) {
          if (oo.id == i.option_id) {
            existing_options.push({
              id: i.option_id,
              next_question: oo.next_question,
              option_id: i.option_id,
              option_string: i.option_string,
              question_id: answer_obj.id,
              question_type: answer_obj.question_type,
            });


            if (oo.next_question) {
              next_question = oo.next_question;
            }

          }
        }
      }

      console.log("options are");
      console.log(questions_answers);

      // Change of plans, this is what we will do. Get the options from the existing array. 
      // Take the next question id from that option. Post that run the for loop until the next_question param is null. That means all nested questions are deleted.

      console.log('The existing options are');
      console.log(existing_options);

      // Now get the existing questions.
      let existing_questions = [], option = null, question = null, question_index;

      if (existing_options.length) {
        next_question = existing_options[0].next_question;
      }

      let indices_to_remove = [];
      let removable_ids = []

      console.log('The current next question id is');
      console.log(next_question);

      for (question_index in questions) {
        let question_obj = questions[question_index];
        console.log('The question in question is');


        if (question_obj.question.id == next_question) {
          existing_options = [];
          questions_answers = question_obj.user_answers;

          if (questions_answers && questions_answers.length) {

            for (i of questions_answers) {
              for (let oo of question_obj.options) {
                if (oo.id == i.option_id) {
                  if (oo.next_question) {
                    next_question = oo.next_question;
                  }
                }
              }
            }
          }

          indices_to_remove.push(question_index);
          removable_ids.push(question_obj.question.id)
        }
      }

      console.log('The existing quetions are');
      console.log(existing_questions);

      console.log("Indeces to Remove Array");
      console.log(indices_to_remove);
      console.log("Removable ids");
      console.log(removable_ids);

      update_obj.nested_question_id = removable_ids;

      // console.log("All Questions in Single Section");
      // console.log(questions);
      indices_to_remove.reverse();


      if (indices_to_remove && indices_to_remove.length) {
        for (let index_number of indices_to_remove) {
          this.allSections[this.questions_index[answer_obj.question_type]].questionsArry.splice(Number(index_number), 1)
        }
      }


      questions = questions.filter(function (value, index) {
        return indices_to_remove.indexOf(index) == -1;
      })

      let res: any = await this.grsService.updateAnswerOfUserQuestion(update_obj);
      console.log("Update Answer is set");
      res = res.json();
      console.log(res);

      if (res) {
        await this.assainAnswers(answer_obj, obj);
      }


    } catch (error) {
      console.log("error in set answer");
      console.log(error);
      let msg = {
        type: "error",
        message: "Answer couldn't save , Try again!"
      }
      this.openNotification(msg);
      answer_obj.question_data.answer = null
    }
  }


  error_message: any = {
    type: "error",
    message: ""
  }
  show: boolean = false

  openNotification(msg) {
    this.error_message.type = msg.type
    this.error_message.message = msg.message
    this.show = true
  }
  closeModal(close) {
    this.show = close
  }

  async goTONextStep(c) {

    try {


      this.question_id = null
      c.is_finalstep_grs = false
      // c is entire obj inside the allSections Array

      console.log("Next step concept");
      console.log(c);
      let no_validation = false;

      if (c.type == "grs" && c.step == 1) {
        no_validation = true
      }

      console.log("all sections for findout errors");
      console.log(c);


      if (!no_validation) {

        for (let obj of c.questionsArry) {
          // if any one question not answered don't let them go
          let is_array = Array.isArray(obj.user_answers)

          if (is_array) {
            console.log(obj.user_answers);

            if (!obj.user_answers.length) {
              console.log("User not allowed to next step of ", c.type);
              c.valiationError = true
              return
            }
          }


          if (!obj.user_answers) {
            console.log("User not allowed to next step of ", c.type);
            c.valiationError = true
            return
          }

        }
      }

      c.valiationError = false

      let allQuestions = await this.grsService.getAllTotalQuestions();

      if (c.step < c.total_pages) {
        console.log("Below steps inside");

        c.step = c.step + 1
        if (c.type == "external_security") {
          if (c.step == c.total_pages) {
            c.is_finalstep_grs = true
          }
        }
        if (c.step > this.new_grs_steps[c.type]) {
          this.new_grs_steps[c.type] = c.step
        }
        if (c.step >= this.new_grs_steps[c.type]) {
          await this.postConfig();
        }

        await this.getQuestions(c);
        await this.setAllAnswersToUi();



        let newelement: HTMLElement;


        if (c.type == "grs") {
          newelement = document.getElementById('mainHeading' + 0) as HTMLElement;
        }

        if (c.type == "data_governance") {
          newelement = document.getElementById('mainHeading' + 1) as HTMLElement;
        }
        if (c.type == "physical_security") {
          newelement = document.getElementById('mainHeading' + 2) as HTMLElement;
        }
        if (c.type == "internal_security") {
          newelement = document.getElementById('mainHeading' + 3) as HTMLElement;
        }

        if (c.type == "external_security") {
          newelement = document.getElementById('mainHeading' + 4) as HTMLElement;
        }

        newelement.scrollIntoView({ behavior: "smooth" })


      } else {


        for (let obj of this.allSections) {

          if (c.type == "grs") {
            if (obj.type == "data_governance") {
              obj.section_enabled = true
              await this.getQuestions(obj);
              await this.setAllAnswersToUi();
              setTimeout(() => {
                // let element = document.getElementById('mainHeading' + 0) as HTMLElement;
                //  element.click();
                let newelement: HTMLElement = document.getElementById('mainHeading' + 1) as HTMLElement;
                newelement.scrollIntoView({ behavior: "smooth" });
                newelement.click();
              }, 200);
            }
          }


          if (c.type == "data_governance") {
            if (obj.type == "physical_security") {
              obj.section_enabled = true;
              await this.getQuestions(obj);
              await this.setAllAnswersToUi();
              setTimeout(() => {
                // let element = document.getElementById('mainHeading' + 1) as HTMLElement;
                // element.click();
                let newelement: HTMLElement = document.getElementById('mainHeading' + 2) as HTMLElement;
                newelement.scrollIntoView({ behavior: "smooth" });
                newelement.click();
              }, 200);
            }
          }

          if (c.type == "physical_security") {
            if (obj.type == "internal_security") {
              obj.section_enabled = true
              await this.getQuestions(obj);
              await this.setAllAnswersToUi();
              setTimeout(() => {
                // let element = document.getElementById('mainHeading' + 2) as HTMLElement;
                // element.click();
                let newelement: HTMLElement = document.getElementById('mainHeading' + 3) as HTMLElement;
                newelement.scrollIntoView({ behavior: "smooth" });
                newelement.click();
              }, 200);
            }
          }


          if (c.type == "internal_security") {
            if (obj.type == "external_security") {

              obj.section_enabled = true
              await this.getQuestions(obj)
              await this.setAllAnswersToUi()
              setTimeout(() => {
                // let element = document.getElementById('mainHeading' + 3) as HTMLElement;
                // element.click();
                let newelement: HTMLElement = document.getElementById('mainHeading' + 4) as HTMLElement;
                newelement.scrollIntoView({ behavior: "smooth" });
                newelement.click();
              }, 200);
            }
          }

          if (c.type == "external_security") {
            // now we are going to calculate and show score in front end
            console.log("Hey this is last for GRS You know");
            c.is_finalstep_grs = true
            this.nowShowScore.emit(true)
            setTimeout(() => {
              let newelement: HTMLElement = document.getElementById('ShowScoreChart') as HTMLElement;
              newelement.scrollIntoView({ behavior: "smooth" });
            }, 200);
          }

        }
        console.log('User finshes the all steps in this section');
      }
    } catch (error) {
      console.log("error");
      console.log(error);
    }

  }


  async goToThatStep(stepNumber, c) {

    try {
      this.question_id = null

      if (stepNumber > this.new_grs_steps[c.type]) {
        console.log("User not allowed to next step of ", c.type);
        return;
      }
      if (stepNumber <= c.total_pages) {
        c.step = stepNumber;
        await this.getQuestions(c)
        await this.setAllAnswersToUi()
      } else {
        console.log('User finshes the all steps in this section');
      }


    } catch (error) {
      console.log("Error");
      console.log(error);
    }
  }


  async setAllAnswersToUi() {
    try {

      // combining the questions and answers by id and score
      let index, small_obj, obj;
      for (obj of this.allSections) {

        if (obj.questionsArry && obj.questionsArry.length) {
          // now if the questions exist in that section?
          index = 0;
          for (small_obj of obj.questionsArry) {
            // take the question

            // now if the user has answered that question?

            if (small_obj.user_answers[0]) {
              // console.log("user answers of Question");
              // console.log(small_obj.user_answers);

              let answer_option_id = small_obj.user_answers[0].option_id
              small_obj.answer = answer_option_id

              if (small_obj.question.answer_type == "ocq" && !small_obj.question.is_checkbox) {
                small_obj.answer = small_obj.user_answers[0].option
              }

              if (small_obj.question.answer_type == "text") {
                small_obj.answer = small_obj.user_answers[0].option_string
              }



              // if answer_type is mcq , answer is array                  
              if (small_obj.question.answer_type == "mcq") {
                let all_answers = small_obj.user_answers;
                let ids = []
                for (let opt_object of all_answers) {
                  let ids_obj = {
                    id: opt_object.option_id,
                    option: opt_object.option
                  }
                  ids.push(ids_obj)
                  for (let ob of small_obj.options) {
                    if (ob.id == ids_obj.id) {
                      ob.checked = true
                    }
                  }
                }
                small_obj.answer = ids
                small_obj.submitted = false
              }

              for (let o of small_obj.options) {
                if (o.id == answer_option_id) {

                  o.checked = true
                  // o.record = this.allAnswers[key].options[0].record
                  if (o.next_question) {

                    // this function insert the nested (conditional) question into actual arry by id
                    // if next_question  for exicisting question this function do api call and get the nested question

                    await this.getNewQuestionInset(o, index, obj.questionsArry);
                  }
                }
              }
            }
            index++;
          }

        }
      }

    } catch (error) {
      console.log("error");
      console.log(error);
    }

  }



  async getNewQuestionInset(option, i, section_array) {

    try {

      let question_id = option.next_question

      if (!question_id) {
        console.log("Question Id is Required");
        return
      }

      this.question_id = option.next_question
      let notInArrayy = true

      // this for loop check the only one array of one Section 
      for (let obj of section_array) {

        if (obj.question.id == this.question_id) {
          // Already the question inside the array
          // so dont insert again
          console.log("it is in array");
          console.log(this.question_id);

          this.question_id = null
          notInArrayy = false

          return
        }
      }

      // lets find the question already exits in arry or not
      // this for loop check the entire all Question we have
      for (let section of this.allSections) {
        let single_section_array = section.questionsArry
        if (single_section_array && single_section_array.length) {
          for (let single_qus_obj of single_section_array) {
            if (single_qus_obj.question.id == this.question_id) {
              // Already the question inside the array
              // so dont insert again  
              // now stop excicuting the function
              console.log("it is in array");
              console.log(this.question_id);

              this.question_id = null
              notInArrayy = false
              return
            }
          }
        }
      }


      if (question_id && notInArrayy) {

        let single_qus: any = await this.insetDataTOArray(question_id)
        console.log(single_qus, 'single quss');


        if (single_qus.user_answers[0]) {

          let answer_option_id = single_qus.user_answers[0].option_id
          single_qus.answer = answer_option_id

          if (single_qus.question.answer_type == "ocq" && !single_qus.question.is_checkbox) {
            single_qus.answer = single_qus.user_answers[0].option
          }

          if (single_qus.question.answer_type == "text") {
            single_qus.answer = single_qus.user_answers[0].option
          }



          // if answer_type is mcq , answer is array                  
          if (single_qus.question.answer_type == "mcq") {
            let all_answers = single_qus.user_answers;
            let ids = []
            for (let opt_object of all_answers) {
              let ids_obj = {
                id: opt_object.option_id,
                option: opt_object.option
              }
              ids.push(ids_obj)
              for (let ob of single_qus.options) {
                if (ob.id == ids_obj.id) {
                  ob.checked = true
                }
              }
            }
            single_qus.answer = ids
            single_qus.submitted = false
          }

        }


        console.log("Inserting into array");
        console.log(this.question_id);

        section_array.splice(i + 1, 0, single_qus)
        this.question_id = null

        // it is exists in answers
        if (single_qus.user_answers[0]) {
          let answer_option_id = single_qus.user_answers[0].option_id;
          // we are checking nested questions inside nested question
          for (let o of single_qus.options) {
            // this question options and qnswer option if matched 
            // we should check that option have next question or not
            if (o.id == answer_option_id) {
              if (o.next_question) {
                let in_answers_also_exits = true
                // let in_answers_also_exits = this.allAnswers[o.next_question];
                // if it is in answers we are taking the question and inserting
                if (in_answers_also_exits) {
                  await this.getNewQuestionInset(o, i + 1, section_array)
                  return
                }
              }
            }
          }
        }

      }


    } catch (error) {
      console.log('some error');
      console.log(error);

    }

  }


  async insetDataTOArray(question_id) {

    let single_obj;
    try {
      let res: any = await this.grsService.getQuestionType(this.page, this.per_page, null, question_id);
      single_obj = res.questions
    } catch (error) {
      console.log(error);
    }
    this.loading = false
    return single_obj
  }


  async getUserEligibitySections() {
    try {

      let return_type = '';
      let total_pages = {
        "grs": 4,
        'data_governance': 4,
        'physical_security': 4,
        'internal_security': 4,
        'external_security': 2
      }

      let allQus = await this.grsService.getAllTotalQuestions();

      for (let key in total_pages) {
        for (let steps_key in this.new_grs_steps) {
          if (steps_key == key) {
            if (Number(total_pages[key]) <= Number(this.new_grs_steps[steps_key])) {

              if (key == "grs") {
                this.allSections[1].section_enabled = true
                await this.getQuestions(this.allSections[1]);
                await this.setAllAnswersToUi();
                let somenewelement: HTMLElement = document.getElementById('mainHeading' + 0) as HTMLElement;
                if (somenewelement) {
                  somenewelement.click();
                }
                return_type = 'grs_score';
              }

              if (key == "data_governance") {
                this.allSections[2].section_enabled = true
                await this.getQuestions(this.allSections[2]);
                await this.setAllAnswersToUi()
                return_type = 'data_governance';
              }
              if (key == "physical_security") {
                this.allSections[3].section_enabled = true
                await this.getQuestions(this.allSections[3]);
                await this.setAllAnswersToUi()
                return_type = 'physical_security';

              }
              if (key == "internal_security") {
                this.allSections[4].section_enabled = true
                await this.getQuestions(this.allSections[4]);
                await this.setAllAnswersToUi()
                return_type = 'internal_security';
              }
            }
          }
        }
      }

      return return_type;


    } catch (error) {
      console.log(error, 'error');
    };

  }


  //// Using ////
  updateCrp() {
    this.needCRPUpdateCRP.emit(true);
  }



  async createCrp() {

    this.loading = true

    try {
      let res = await this.airtable.crpInfo(this.crp_data);
      let response = res.json()
      if (response.success) {

        console.log('success');
      }
    } catch (error) {
      console.log(error);
    }
  }


  //////////////////////////////
  //// Migration Ends Here ////
  ///////////////////////////




















































  // async ngOnChanges() { }

  // async getAllAnswers() {
  //   try {
  //     let res: any = await this.grsService.getAllanswers();
  //     res = res.json();

  //     if (res.success) {
  //       this.allAnswers = res.answers
  //       this.setBulletPoints();
  //       console.log("All Answers are", this.allAnswers);
  //       console.log("All Sections are ", this.allSections);
  //     }

  //   } catch (error) {
  //     console.log(error, 'error');
  //   }
  // }


  // "ng-chartist": "^2.0.0-beta.1"

  // async onlyForAnswerCounts() {
  //   try {
  //     let res: any = await this.grsService.getAllanswers();
  //     res = res.json();

  //     if (res.success) {
  //       this.counter_obj = {
  //         data_governance: 0,
  //         physical_security: 0,
  //         internal_security: 0,
  //         external_security: 0
  //       }
  //       let data_type, length_of_options, multiple_consider;
  //       for (let key in res.answers) {
  //         data_type = res.answers[key].question_type
  //         length_of_options = res.answers[key].options.length
  //         multiple_consider = res.answers[key].multiple_question_consideration
  //         if (multiple_consider && multiple_consider == 'YES') {
  //           this.counter_obj[data_type] = this.counter_obj[data_type] + Number(length_of_options)
  //         } else if (data_type) {
  //           this.counter_obj[data_type] = this.counter_obj[data_type] + 1;
  //         }
  //       }
  //     }

  //   } catch (error) {
  //     console.log(error, 'error');
  //   }
  // }



  // async getScores(only_update_score) {
  //   try {
  //     let res: any = await this.airtable.getScores()
  //     console.log("response of Getscores :", res);

  //     if (res.success) {
  //       this.getScoresDetails = res.success;
  //       if (this.getScoresDetails || this.getScoresDetails[0]) {
  //         if (this.getScoresDetails[0]) {
  //           this.getScoresDetails = this.getScoresDetails[0];
  //         }
  //       }

  //       this.scores = {
  //         css: this.getScoresDetails.CSS_Score == '0' ? this.getScoresDetails.CSS_Score : Number(this.getScoresDetails.CSS_Score).toFixed(3),
  //         cis: this.getScoresDetails.CIS_Score == '0' ? this.getScoresDetails.CIS_Score : Number(this.getScoresDetails.CIS_Score).toFixed(3),
  //         grs: this.getScoresDetails.GRS_Score == '0' ? this.getScoresDetails.GRS_Score : Number(this.getScoresDetails.GRS_Score).toFixed(3),
  //       }

  //       return this.getScoresDetails;
  //     }

  //   } catch (error) {
  //     console.log(error, 'err');
  //   }
  // }






  // async postAnswerToAirtable(data) {
  //   // sending answer to airtable for every single question
  //   this.loading = true
  //   try {

  //     console.log('The data that is being sent to airtable is');
  //     data.total_count_question_answered = this.counter_obj[data.question.question_type];
  //     data.current_scores = this.getScoresDetails;
  //     console.log(data); // The current question object.
  //     // How do we get the current selected option?

  //     let is_update = false;
  //     if (data.question) {
  //       if (data.question.record) {
  //         console.log('This is an update question');
  //         is_update = true;
  //       }
  //     }

  //     if (!is_update) {
  //       this.loading = true
  //       let res: any = await this.grsService.updateAnswer(data);

  //       // this.getScores(true);
  //       // await this.getAllAnswers()
  //       // this.setAllAnswersToUi()
  //       // this.loading = false

  //     } else {

  //       this.loading = true
  //       console.log('The logic for the update is pending');
  //       console.log('The all answers is');
  //       console.log(this.allAnswers);
  //       console.log('\n\n\n\n all questions are');

  //       let questions: any = this.allSections[this.questions_index[data.question.question_type]].questionsArry;

  //       console.log(questions);

  //       // Time to transaform the object.
  //       // let update_object = JSON.parse(JSON.stringify(data));
  //       data.is_update = is_update;
  //       console.log("The question id is");
  //       console.log(data.question.id);

  //       let questions_answers = this.allAnswers[data.question.id];
  //       let existing_options = [];

  //       let next_question = null, i;

  //       console.log("options are");
  //       console.log(questions_answers);
  //       for (i of questions_answers.options) {
  //         existing_options.push({
  //           id: i.option.id,
  //           next_question: i.option.next_question,
  //           option_id: i.option.option_id,
  //           option_string: i.option.option_string,
  //           question_id: i.option.question_id,
  //           question_type: i.option.question_type,
  //           score: i.option.score,
  //           user_id: i.option.user_id,
  //           record: i.record
  //         });

  //         if (i.option.next_question) {
  //           next_question = i.option.next_question;
  //         }
  //       }

  //       // Change of plans, this is what we will do. Get the options from the existing array. 
  //       // Take the next question id from that option. Post that run the for loop until the next_question param is null. That means all nested questions are deleted.

  //       console.log('The existing options are');
  //       console.log(existing_options);

  //       data.old_options = existing_options;

  //       // Now get the existing questions.
  //       let existing_questions = [], option = null, question = null, question_index;

  //       // existing_options = [];

  //       if (existing_options.length) {
  //         next_question = existing_options[0].next_question;
  //       }

  //       let indices_to_remove = [];

  //       console.log('The current next question id is');
  //       console.log(next_question);

  //       for (question_index in questions) {
  //         let question_obj = questions[question_index];
  //         console.log('The question in question is');
  //         // console.log(question_obj);

  //         if (question_obj.question.id == next_question) {

  //           existing_options = [];
  //           // Then add this question to the array.
  //           // console.log(question_obj);
  //           // if (question_obj.question.answer_type != "text") {
  //           questions_answers = this.allAnswers[question_obj.question.id];
  //           console.log(questions_answers, 'Question and Answers');

  //           if (questions_answers && questions_answers.options) {

  //             for (i of questions_answers.options) {
  //               let exicisting_object: any = {
  //                 id: i.option.id,
  //                 option_id: i.option.option_id,
  //                 question_id: i.option.question_id,
  //                 question_type: i.option.question_type,
  //                 user_id: i.option.user_id,
  //                 record: i.record
  //               }

  //               if (i.option.option_string) {
  //                 exicisting_object.option_string = i.option.option_string
  //               }

  //               if (i.option.next_question) {
  //                 exicisting_object.next_question = i.option.next_question
  //               }

  //               if (i.option.score) {
  //                 exicisting_object.score = i.option.score
  //               }

  //               existing_options.push(exicisting_object);

  //               if (i.option.next_question) {
  //                 next_question = i.option.next_question;
  //               }
  //             }

  //           }

  //           indices_to_remove.push(question_index);
  //           existing_questions.push({
  //             question: question_obj,
  //             options: existing_options
  //           });

  //         }
  //       }

  //       console.log('The existing quetions are');
  //       console.log(existing_questions);

  //       data.nested_questions = existing_questions;

  //       console.log("The final data object is");
  //       console.log(JSON.stringify(data, null, 4));

  //       console.log("Indeces to Remove Array");
  //       console.log(indices_to_remove);

  //       console.log("All Questions in Single Section");
  //       console.log(questions);
  //       indices_to_remove.reverse();

  //       if (indices_to_remove && indices_to_remove.length) {
  //         for (let index_number of indices_to_remove) {
  //           this.allSections[this.questions_index[data.question.question_type]].questionsArry.splice(Number(index_number), 1)
  //         }
  //       }


  //       // questions = questions.filter(function (value, index) {
  //       //   return indices_to_remove.indexOf(index) == -1;
  //       // })

  //       // Do the api call here to update answers in the backend, with the data object

  //       let updating_answer: any = await this.grsService.updateAnswerOfUserQuestion(data)
  //       console.log("updating answer now", updating_answer.json());
  //       // this.getScores(true);
  //       // await this.getAllAnswers();
  //       // this.setAllAnswersToUi()

  //       this.loading = false
  //       // Now, the indices to remove is an array that has the indices of questions that have to be removed from the array. that thing has to be checked. 
  //       // Use this this.getAllAnswers() to update the answer state in the UI.
  //     }
  //   } catch (error) {
  //     console.log(error, 'error on post answer');
  //     this.loading = false
  //   }
  //   this.loading = false
  // }






  // narrative_bullet_points: any = {

  //   data_governance: {
  //     NC01: "Impact of compliance and regulatory requirements",
  //     NC02: "Payment methods and amount of personal information collected and stored ect/Store personal Information",
  //     NG01: "Information Security Policy documentation, communication and training",
  //     NG02: "Incident management process",
  //     NG03: "Encryption policy",
  //     NG04: "Policies on destruction of paper and electronic information",
  //     NG05: "Assigning, changing and auditing user accounts",
  //     NG06: "Unauthorized software",
  //     NG07: "Utilization of background checks",
  //     NG08: "Disaster Recovery plans",
  //     NG09: "Ongoing Information Security Risk Assessments",
  //     NG10: "Outsourced services and vendor risk",
  //     NG11: "Cyber Risk Transfer",
  //   },
  //   physical_security: {
  //     NP01: "Physical security policies and assessment",
  //     NP02: "Access of vendors and visitors to the organization facilities ",
  //     NP03: "Data center access control ",
  //     NP04: "Back- up power and environmental controls ",
  //     NP05: "3rd Party data center or colocation utilization ",
  //   },
  //   external_security: {
  //     NE01: "External IP addresses utilized ",
  //     NE02: "Monitoring of traffic between internal and external networks and intrusion detection  ",
  //     NE03: "Utilization of E- Commerce ",
  //     NE04: "Remote access and authentication ",
  //     NE05: "Cloud services utilization and incident communication ",
  //     NE06: "Encryption for data transfers ",
  //     NE07: "External vulnerability scanning  ",
  //     NE08: "Penetration testing ",
  //   },
  //   internal_security: {
  //     NT01: "Internet circuits, firewalls and devices utilized",
  //     NT02: "Servers, workstations and laptops utilized",
  //     NT03: "Company owned mobile devices",
  //     NT04: "Personally owned mobile devices",
  //     NT05: "Network segmentation, management network, and DMZ’s",
  //     NT06: "Network based intrusion detection",
  //     NT07: "Network architecture and vendor network support",
  //     NT08: "Patch management process and documentation",
  //     NT09: "Vulnerability scans and resolution of issues identified",
  //     NT10: "Network  and server change process",
  //     NT11: "Laptop encryption",
  //     NT12: "Mobile device management",
  //     NT13: "Removable media",
  //     NT14: "Involvement in software development",
  //     NT15: "Hardened server and network standards",
  //     NT16: "Anti - Virus software and updates",
  //     NT17: "Network logging and monitoring standards",
  //     NT18: "Wireless network segmentation, firewalls and auditing",
  //     NT19: "User administrative rights",
  //   },

  // }

  // bullet_points_set: boolean = false
  // user_narative_bullet_points: any = {
  //   data_governance: {},
  //   physical_security: {},
  //   internal_security: {},
  //   external_security: {}
  // }


  // setBulletPoints() {
  //   if (this.allAnswers) {

  //     for (let key in this.allAnswers) {
  //       let options = this.allAnswers[key].options;
  //       for (let obj of options) {
  //         let tags = obj.option.tags;
  //         let question_type = obj.option.question_type
  //         if (tags && question_type) {
  //           this.user_narative_bullet_points[question_type][tags] = this.narrative_bullet_points[question_type][tags];
  //         }
  //       }
  //     }

  //     this.bullet_points_set = true
  //     console.log('user narrative bullet points are');
  //     for (let k in this.narrative_bullet_points) {
  //       this.user_narative_bullet_points[k + "_arry"] = Object.values(this.user_narative_bullet_points[k])
  //     }

  //     console.log(this.user_narative_bullet_points);
  //     // let data_governance = user_narative_bullet_points['data_governance'];
  //     // strings_to_render = Object.values(data_governance);
  //   }
  // }

}


