import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
// import * as firebase from "firebase/app";
import { GrsService } from "./../../services/grs.service";

import { AirtableService } from "./../../services/airtable.service";
import { AuthService } from "./../../services/auth.service";
import { CONFIG } from "../../config/config";
@Component({
  selector: 'app-grs-report',
  templateUrl: './grs-report.component.html',
  styleUrls: ['./grs-report.component.scss']
})
export class GrsReportComponent implements OnInit, AfterViewInit {


  crp_data: any
  narrative_bullet_points: any = {
    grs: {
      NC01: "Impact of compliance and regulatory requirements",
      NC02: "Payment methods and amount of personal information collected and stored ect/Store personal Information",
      NG01: "Information Security Policy documentation, communication and training",
      NG02: "Incident management process",
      NG03: "Encryption policy",
      NG04: "Policies on destruction of paper and electronic information",
      NG05: "Assigning, changing and auditing user accounts",
      NG06: "Unauthorized software",
      NG07: "Utilization of background checks",
      NG08: "Disaster Recovery plans",
      NG09: "Ongoing Information Security Risk Assessments",
      NG10: "Outsourced services and vendor risk",
      NG11: "Cyber Risk Transfer",
    },
    data_governance: {
      NC01: "Impact of compliance and regulatory requirements",
      NC02: "Payment methods and amount of personal information collected and stored ect/Store personal Information",
      NG01: "Information Security Policy documentation, communication and training",
      NG02: "Incident management process",
      NG03: "Encryption policy",
      NG04: "Policies on destruction of paper and electronic information",
      NG05: "Assigning, changing and auditing user accounts",
      NG06: "Unauthorized software",
      NG07: "Utilization of background checks",
      NG08: "Disaster Recovery plans",
      NG09: "Ongoing Information Security Risk Assessments",
      NG10: "Outsourced services and vendor risk",
      NG11: "Cyber Risk Transfer",
    },
    physical_security: {
      NP01: "Physical security policies and assessment",
      NP02: "Access of vendors and visitors to the organization facilities ",
      NP03: "Data center access control ",
      NP04: "Back- up power and environmental controls ",
      NP05: "3rd Party data center or colocation utilization ",
    },
    external_security: {
      NE01: "External IP addresses utilized ",
      NE02: "Monitoring of traffic between internal and external networks and intrusion detection  ",
      NE03: "Utilization of E- Commerce ",
      NE04: "Remote access and authentication ",
      NE05: "Cloud services utilization and incident communication ",
      NE06: "Encryption for data transfers ",
      NE07: "External vulnerability scanning  ",
      NE08: "Penetration testing ",
    },
    internal_security: {
      NT01: "Internet circuits, firewalls and devices utilized",
      NT02: "Servers, workstations and laptops utilized",
      NT03: "Company owned mobile devices",
      NT04: "Personally owned mobile devices",
      NT05: "Network segmentation, management network, and DMZ’s",
      NT06: "Network based intrusion detection",
      NT07: "Network architecture and vendor network support",
      NT08: "Patch management process and documentation",
      NT09: "Vulnerability scans and resolution of issues identified",
      NT10: "Network  and server change process",
      NT11: "Laptop encryption",
      NT12: "Mobile device management",
      NT13: "Removable media",
      NT14: "Involvement in software development",
      NT15: "Hardened server and network standards",
      NT16: "Anti - Virus software and updates",
      NT17: "Network logging and monitoring standards",
      NT18: "Wireless network segmentation, firewalls and auditing",
      NT19: "User administrative rights",
    },

  }
  allAnswers: any
  bullet_points_set: boolean = false
  user_narative_bullet_points: any = {
    grs: {},
    data_governance: {},
    physical_security: {},
    internal_security: {},
    external_security: {}
  }
  loading: boolean = false

  GRS_QUS: any = CONFIG.QUESTIONS.GRS;

  risk_texts: any = {
    grs: '',
    css: '',
    cis: "",
    composite_grs_score: ""
  }
  set_classnames = {
    grs: '',
    css: '',
    cis: "",
    composite_grs_score: ""
  }


  employee_factor: any = {
    "0": "1.000",
    "1 - 49": "0.990",
    "50 - 199": "0.980",
    "200 - 499": "0.970",
    "500 - 999": "0.960",
    "1,000 - 4, 999": "0.950",
    "5,000 - 9, 999": "0.940",
    "10,000 - 24, 999": "0.925",
    "25,000 or more": "0.915"
  }
  employee_factor_number: any;

  footprint: any;
  scores: any = {
    css: 0,
    grs: 0,
    cis: 0,
    composite_grs_score: 0
  }

  getScoresDetails: any = {
    data_governance: "",
    physical_security: "",
    internal_security: "",
    external_security: ""
  };

  constructor(
    private grsService: GrsService,
    private _ngZone: NgZone,
    private airtable: AirtableService,
    private authService: AuthService,
  ) { }
  ngOnInit() {
    this.bullet_points_set = true
    this.getUserInfo();

  }


  ngAfterViewInit() { }


  async getUserInfo() {
    try {
      let res = await this.authService.profile();
      let response = res.json()
      console.log(response);
      if (response.success) {
        if (response.user) {

          this.crp_data = response.user

          this.footprint = this.crp_data.global_footprint;

          this.crp_data.cis_score = parseFloat(this.crp_data.cis_score);
          this.crp_data.css_score = parseFloat(this.crp_data.css_score);
          this.crp_data.grs_score = parseFloat(this.crp_data.grs_score);


          if (this.crp_data.cis_score) {
            this.scores = {
              css: this.crp_data.css_score == '0' ? this.crp_data.css_score : Number(this.crp_data.css_score).toFixed(3),
              cis: this.crp_data.cis_score == '0' ? this.crp_data.cis_score : Number(this.crp_data.cis_score).toFixed(3),
              grs: this.crp_data.grs_score == '0' ? this.crp_data.grs_score : Number(this.crp_data.grs_score).toFixed(3),
            }

            // if composite_grs exits priory should be for this
            if (this.crp_data.composite_grs_score) {

              let data_governance = this.crp_data.data_governance_score
              let physical_security = this.crp_data.physical_security_score
              let internal_security = this.crp_data.internal_security_score
              let external_security = this.crp_data.external_security_score

              this.getScoresDetails = this.crp_data;
              this.getScoresDetails.data_governance = Number(this.crp_data.data_governance_score).toFixed(3)
              this.getScoresDetails.physical_security = (Number(this.crp_data.physical_security_score)).toFixed(3)
              this.getScoresDetails.internal_security = (Number(this.crp_data.internal_security_score)).toFixed(3)
              this.getScoresDetails.external_security = (Number(this.crp_data.external_security_score)).toFixed(3)


              if (data_governance && physical_security && internal_security && external_security) {
                if (Number(this.crp_data.composite_grs_score) > 0) {
                  this.scores.composite_grs_score = Number(this.crp_data.composite_grs_score).toFixed(3)
                }
              } else {
                // Dont show the GRS Score
              }
            }

          }
          this.updateScoreText();
          await this.setUserNarratives()
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async setUserNarratives() {
    this.loading = true
    try {
      let res: any = await this.grsService.getAllTotalQuestions();
      console.log("Response of user answers");
      console.log(res);

      if (res) {
        let _th = this;
        let options, tags, question_type, obj, o;

        // i'm adding NC02 as well because we need to show always
        this.user_narative_bullet_points.data_governance["NC02"] = this.narrative_bullet_points.data_governance["NC02"];

        for (question_type in res) {
          for (o of res[question_type]) {
            if (o && o.user_answers) {
              options = o.user_answers;
              for (obj of options) {
                if (obj.tag) {
                  tags = obj.tag;
                  if (tags && question_type) {
                    _th.user_narative_bullet_points[question_type][tags] = _th.narrative_bullet_points[question_type][tags];
                  }
                }
              }
            }

            _th.bullet_points_set = true
            // console.log('user narrative bullet points are', _th.bullet_points_set);
            // console.log(_th.user_narative_bullet_points);

            for (let k in _th.narrative_bullet_points) {
              _th.user_narative_bullet_points[k + "_arry"] = Object.values(_th.user_narative_bullet_points[k])
            }
          }
        }


      }
    } catch (error) {
      console.log("error");
      console.log(error);
    }
    this.loading = false
  }



  print: boolean = false
  async printing() {
    this.print = true
    await setTimeout(() => {
      window.print()
      this.print = false
    }, 200);

  }



  updateScoreText() {

    let set_classnames = {
      grs: '',
      css: '',
      cis: "",
      composite_grs_score: ""
    }

    let classNames = ['major_risk', 'cautionary_risk', 'moderate_risk', 'minor_risk', 'acceptable_risk'];

    for (let i in this.scores) {
      if (this.scores[i] == 0) {
        set_classnames[i] = "major_risk zero_score"
      } else if (this.scores[i] < 0.5) {
        set_classnames[i] = classNames[0]
        this.risk_texts[i] = 'Major Risk';
      } else if (this.scores[i] < 0.6) {
        set_classnames[i] = classNames[1]
        this.risk_texts[i] = 'Cautionary Risk';
      } else if (this.scores[i] < 0.7) {
        set_classnames[i] = classNames[2]
        this.risk_texts[i] = 'Moderate Risk';
      } else if (this.scores[i] < 0.8) {
        set_classnames[i] = classNames[3]
        this.risk_texts[i] = 'Minor Risk';
      } else {
        set_classnames[i] = classNames[4]
        this.risk_texts[i] = 'Acceptable Risk';
      }
    }
    for (let i in this.scores) {
      if (i == 'composite_grs_score') {
        if (this.scores[i] == 0) {
          set_classnames[i] = "major_risk zero_score"
        } else if (this.scores[i] > 0 && this.scores[i] <= 0.559) {
          set_classnames[i] = classNames[0]
          this.risk_texts[i] = 'Major Risk';
        } else if (this.scores[i] >= 0.560 && this.scores[i] <= 0.625) {
          set_classnames[i] = classNames[1]
          this.risk_texts[i] = 'Cautionary Risk';
        } else if (this.scores[i] >= 0.626 && this.scores[i] <= 0.664) {
          set_classnames[i] = classNames[2]
          this.risk_texts[i] = 'Moderate Risk';
        } else if (this.scores[i] >= 0.665 && this.scores[i] <= 0.7) {
          set_classnames[i] = classNames[3]
          this.risk_texts[i] = 'Minor Risk';
        } else {
          set_classnames[i] = classNames[4]
          this.risk_texts[i] = 'Acceptable Risk';
        }
      }
    }

    this.set_classnames = set_classnames
    
  }






















  
    // let Major_composite_score = 0;
    // // if (this.scores.composite_grs_score) {
    // //   Major_composite_score = this.scores.composite_grs_score
    // // } else {
    // //   Major_composite_score = 0

    // //   set_classnames["composite_grs_score"] = "major_risk zero_score"
    // // }
  // if (_th.allAnswers) {
  //   for (let key in _th.allAnswers) {
  //     options = _th.allAnswers[key].options;
  //     for (let obj of options) {
  //       tags = obj.option.tags;
  //       question_type = obj.option.question_type
  //       if (tags && question_type) {
  //         _th.user_narative_bullet_points[question_type][tags] = _th.narrative_bullet_points[question_type][tags];
  //       }
  //     }
  //   }

  //   _th.bullet_points_set = true
  //   console.log('user narrative bullet points are', _th.bullet_points_set);
  //   for (let k in _th.narrative_bullet_points) {
  //     _th.user_narative_bullet_points[k + "_arry"] = Object.values(_th.user_narative_bullet_points[k])
  //   }
  // }

  // let choose_ans;
  // let narrative_code;
  // // for old grs narrative bullet points
  // for (let obj of this.GRS_QUS) {
  //   for (let key in this.crp_data) {
  //     if (obj.question == key) {
  //       // now question from airtable and from config is same 
  //       // now check the ansewr has narrative point
  //       choose_ans = this.crp_data[key];
  //       if (obj.narraties) {
  //         // this having narratives obj now chech ans and narative matched 
  //         // show that bullet point
  //         narrative_code = obj.narraties[choose_ans];
  //         if (narrative_code) {
  //           console.log("Old grs narrative bullet point has been added");
  //           console.log('that code is', narrative_code);
  //           this.user_narative_bullet_points.data_governance[narrative_code] = this.narrative_bullet_points.data_governance[narrative_code];
  //           // i'm adding NC02 as well because we need to show always
  //           this.user_narative_bullet_points.data_governance["NC02"] = this.narrative_bullet_points.data_governance["NC02"];
  //         }
  //       }
  //     }
  //   }
  // }

  // async getAllAnswers() {
  //   let _th = this
  //   this.loading = true
  //   try {
  //     let res: any = await this.grsService.getAllanswers();
  //     res = res.json();

  //     if (res.success) {
  //       this.allAnswers = res.answers
  //       console.log("All Answers are", this.allAnswers);
  //       let options, tags, question_type;
  //       if (_th.allAnswers) {
  //         for (let key in _th.allAnswers) {
  //           options = _th.allAnswers[key].options;
  //           for (let obj of options) {
  //             tags = obj.option.tags;
  //             question_type = obj.option.question_type
  //             if (tags && question_type) {
  //               _th.user_narative_bullet_points[question_type][tags] = _th.narrative_bullet_points[question_type][tags];
  //             }
  //           }
  //         }

  //         _th.bullet_points_set = true
  //         console.log('user narrative bullet points are', _th.bullet_points_set);
  //         for (let k in _th.narrative_bullet_points) {
  //           _th.user_narative_bullet_points[k + "_arry"] = Object.values(_th.user_narative_bullet_points[k])
  //         }

  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, 'error');
  //     this.loading = false
  //   }

  //   this.loading = false
  // }



  // async getCrpData() {
  //   this.loading = true
  //   try {
  //     let res: any = await this.airtable.getCrpData();
  //     this.crp_data = res.success;
  //     if (this.crp_data.length > 0) {
  //       this.crp_data = this.crp_data[0];

  //       console.log(this.crp_data, "data some");

  //       this.employee_factor_number = Number(this.employee_factor[this.crp_data["Total number of employees"]]);

  //       console.log(this.employee_factor_number, 'factor num');


  //       this.getScores()
  //       // this.scores = {
  //       //   css: this.crp_data.CSS_Score == '0' ? this.crp_data.CSS_Score : Number(this.crp_data.CSS_Score).toFixed(3),
  //       //   cis: this.crp_data.CIS_Score == '0' ? this.crp_data.CIS_Score : Number(this.crp_data.CIS_Score).toFixed(3),
  //       //   grs: this.crp_data.GRS_Score == '0' ? this.crp_data.GRS_Score : Number(this.crp_data.GRS_Score).toFixed(3),
  //       // }

  //       // if composite_grs exits priory should be for this
  //       if (this.crp_data.composite_grs_score) {
  //         let data_governance = this.crp_data.data_governance
  //         let physical_security = this.crp_data.physical_security
  //         let internal_security = this.crp_data.internal_security
  //         let external_security = this.crp_data.external_security

  //         if (data_governance && physical_security && internal_security && external_security) {
  //           if (Number(this.crp_data.composite_grs_score) > 0) {
  //             this.scores.composite_grs_score = Number(this.crp_data.composite_grs_score).toFixed(3)
  //           }
  //         } else { }
  //       }

  //       // this.updateScoreText()

  //       console.log("Total CRP Data is :", this.crp_data);


  //       let choose_ans;
  //       let narrative_code;
  //       // for old grs narrative bullet points
  //       for (let obj of this.GRS_QUS) {
  //         for (let key in this.crp_data) {
  //           if (obj.question == key) {
  //             // now question from airtable and from config is same 
  //             // now check the ansewr has narrative point
  //             choose_ans = this.crp_data[key];
  //             if (obj.narraties) {
  //               // this having narratives obj now chech ans and narative matched 
  //               // show that bullet point
  //               narrative_code = obj.narraties[choose_ans];
  //               if (narrative_code) {
  //                 console.log("Old grs narrative bullet point has been added");
  //                 console.log('that code is', narrative_code);
  //                 this.user_narative_bullet_points.data_governance[narrative_code] = this.narrative_bullet_points.data_governance[narrative_code];
  //                 // i'm adding NC02 as well because we need to show always
  //                 this.user_narative_bullet_points.data_governance["NC02"] = this.narrative_bullet_points.data_governance["NC02"];
  //               }
  //             }
  //           }
  //         }
  //       }


  //     }
  //   } catch (err) {
  //     console.log(err);

  //   }
  //   this.loading = false
  // }


  // async getScores() {

  //   this.loading = true

  //   try {
  //     let res: any = await this.airtable.getScores()
  //     console.log("response of Getscores :", res);
  //     this.getScoresDetails = res.success;
  //     if (this.getScoresDetails || this.getScoresDetails[0]) {
  //       if (this.getScoresDetails[0]) {
  //         this.getScoresDetails = this.getScoresDetails[0];
  //       }

  //       this.scores = {
  //         css: this.getScoresDetails.CSS_Score == '0' ? this.getScoresDetails.CSS_Score : Number(this.getScoresDetails.CSS_Score).toFixed(3),
  //         cis: this.getScoresDetails.CIS_Score == '0' ? this.getScoresDetails.CIS_Score : Number(this.getScoresDetails.CIS_Score).toFixed(3),
  //         grs: this.getScoresDetails.GRS_Score == '0' ? this.getScoresDetails.GRS_Score : Number(this.getScoresDetails.GRS_Score).toFixed(3),
  //       }
  //       // if composite_grs exits priory should be for this
  //       if (this.getScoresDetails.composite_grs_score) {

  //         this.getScoresDetails.data_governance = Number(this.getScoresDetails.data_governance).toFixed(3)
  //         this.getScoresDetails.physical_security = (Number(this.getScoresDetails.physical_security)).toFixed(3)
  //         this.getScoresDetails.internal_security = (Number(this.getScoresDetails.internal_security)).toFixed(3)
  //         this.getScoresDetails.external_security = (Number(this.getScoresDetails.external_security)).toFixed(3)

  //         let data_governance = this.getScoresDetails.data_governance
  //         let physical_security = this.getScoresDetails.physical_security
  //         let internal_security = this.getScoresDetails.internal_security
  //         let external_security = this.getScoresDetails.external_security

  //         if (data_governance && physical_security && internal_security && external_security) {
  //           // now we can show the score in Ui
  //           console.log("Now Composite score is ready");
  //           if (Number(this.getScoresDetails.composite_grs_score) > 0) {
  //             this.scores.composite_grs_score = Number(this.getScoresDetails.composite_grs_score).toFixed(3)
  //           }

  //         } else {
  //         }
  //         this.updateScoreText()
  //       }

  //       console.log("Scores Object is : ", this.scores);
  //     }
  //   } catch (error) {
  //     console.log(error, 'err');
  //   }
  //   this.loading = false
  // }

}
