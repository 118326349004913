export const CONFIG = {

     // Client's Base Domain
     // API_URL: "https://cloud-cover-api.apps.fountane.io/api/v1",
     // API_URL : "https://cloud-cover-api-css-change.apps.fountane.io/api/v1",
     // API_URL: "http://cloudcover-api-v2.o.fountane.xyz",
     // API_URL: "https://api.cloudcoverregistry.com",

     // API_URL : "https://28de7cd1.ngrok.io/api/v1",
     API_URL : "https://v2-api.cloudcoverregistry.com/api/v1",

     uid: () => window.localStorage.getItem('uid'),
     footprint: () => window.localStorage.getItem('footprint'),
     jwt: () => window.localStorage.getItem('token'),
     QUESTIONS: {
          "GRS": [
               // {
               //      "question": "Organization Type",
               //      "options": {
               //           "Public": 0.72,
               //           "Private": 0.6
               //      },
               //      "is_checkbox": false
               // },
               // {
               //      "question": "Annual Revenue ($ million)",
               //      "options": {
               //           "Less than 1.0": 0.79,
               //           "1.0 - 4.99": 0.79,
               //           "5.0 - 19.99": 0.72,
               //           "20.0 - 49.99": 0.66,
               //           "50.0 - 99.99": 0.6,
               //           "100.0 - 499.99": 0.6,
               //           "500.0 - 999.99": 0.545,
               //           "1,000.0 - 4,999.99": 0.545,
               //           "5,000.0 or more": 0.5
               //      },
               //      "is_checkbox": false
               // },
               {
                    "question": "Percentage of revenue generated from foreign business activity",
                    "options": {
                         "Zero": 0.79,
                         "1 - 25": 0.72,
                         "26 - 50": 0.6,
                         "51 - 75": 0.6,
                         "76 - 90": 0.545,
                         "91 - 100": 0.545
                    },
                    "is_checkbox": false,
                    "narraties": {
                         "Zero": false,
                         "1 - 25": 'NC01',
                         "26 - 50": 'NC01',
                         "51 - 75": 'NC01',
                         "76 - 90": 'NC01',
                         "91 - 100": 'NC01'
                    }
               },
               {
                    "question": "Number of physical locations - Operating Facilities / Offices",
                    "options": {
                         "1": 0.72,
                         "2 - 3": 0.66,
                         "4 - 6": 0.6,
                         "7 - 10": 0.6,
                         "11 - 15": 0.545,
                         "More than 15": 0.545
                    },
                    "is_checkbox": false,
                    "narraties": {
                         "1": false,
                         "2 - 3": 'NC01',
                         "4 - 6": 'NC01',
                         "7 - 10": 'NC01',
                         "11 - 15": 'NC01',
                         "More than 15": 'NC01'
                    },
               },
               {
                    "question": "Total number of employees",
                    "options":
                    //  {
                    //      "0": 1,
                    //      "1- 49": 1,
                    //      "50 - 199": 0.99,
                    //      "200 - 499": 0.98,
                    //      "500 - 999": 0.97,
                    //      "1,000 - 4,999": 0.96,
                    //      "5,000 - 9,999": 0.95,
                    //      "10,000 - 24,999": 0.94,
                    //      "25,000 or more": 0.925,
                    //      "": 0.915
                    // },
                    {
                         "0": "1.000",
                         "1 - 49": "0.990",
                         "50 - 199": "0.980",
                         "200 - 499": "0.970",
                         "500 - 999": "0.960",
                         "1,000 - 4, 999": "0.950",
                         "5,000 - 9, 999": "0.940",
                         "10,000 - 24, 999": "0.925",
                         "25,000 or more": "0.915"
                    },
  
                    "is_checkbox": false
               },
               {
                    "question": "How many years has your Organization been in existence?",
                    "options": {
                         "Less than 1 year": 0.545,
                         "1 - 3 years": 0.6,
                         "3 - 5 years": 0.6,
                         "5 - 10 years": 0.66,
                         "10 - 20 years": 0.72,
                         "20 - 35 years": 0.72,
                         "more than 35 years": 0.72
                    },
                    "is_checkbox": false
               },
               {
                    "question": "In which business segment does your Organization operate primarily?",
                    "options": {
                         "Govt. Contracting": 0.6,
                         "Financial Svcs": 0.545,
                         "Bio Tech": 0.6,
                         "Retail/Wholesale": 0.6,
                         "Legal/Accounting": 0.6,
                         "Medical/Health": 0.545,
                         "Engineering": 0.6,
                         "CPG": 0.6,
                         "Tech": 0.545,
                         "Prof. Svcs": 0.6
                    },
                    "is_checkbox": false
               },
               {
                    "question": "What other business segments apply to your Organization? Choose all that apply.",
                    "options": {
                         // "Answered": 0,
                         "Adv. Svcs": 0.72,
                         "Architect/Engineer": 0.72,
                         "Construction": 0.72,
                         "Consulting": 0.72,
                         "Education": 0.6,
                         "Financial Svcs": 0.5,
                         "Health Care": 0.5,
                         "Legal Svcs": 0.6,
                         "Manufacturer": 0.72,
                         "Public Svcs": 0.72,
                         "Publish/Broadcast": 0.72,
                         "Real Estate Svcs": 0.72,
                         "Retail/Wholesale": 0.6,
                         "Tech/ Telecom": 0.6,
                         "Transp/Warehsg": 0.72,
                         "Utility Svcs": 0.6,
                         "Govt. Contracting": 0.72,
                         "Other": 0.6
                    },
                    "is_checkbox": false,
                    'formula': 'complicated',
                    "is_multiple": true
               },
               {
                    "question": "Have your customers inquired about your Organization’s information security practices?",
                    "options": {
                         "Yes": 0.72,
                         "No": 0.6,
                         "Not Sure": 0.6
                    },
                    "is_checkbox": true
               },
               {
                    "question": "Do you share sensitive information with vendors or third parties?",
                    "options": {
                         "Yes": 0.545,
                         "No": 0.79,
                         "Not Sure": 0.6
                    },
                    "is_checkbox": true,
                    "narraties": {
                         "Yes": 'NC01',
                         "No": false,
                         "Not Sure": 'NC01'
                    },
               },
               {
                    "question": "Do you collect, store, or manage any of the following types of data? Choose all that apply",

                    "options": {
                         // "Answered": 0,
                         "Credit Debit Card": 0.6,
                         "Bank Acct #'s": 0.6,
                         "Protected Health": 0.6,
                         "Soc Sec #'s": 0.6,
                         "Drive Lic. #'s": 0.6,
                         "Educational": 0.6,
                         "Passport #'s": 0.6
                    },
                    "is_checkbox": false,
                    "is_multiple": true,
                    "formula": "easy"
               },
               {
                    "question": "Do you create or process credit / debit card payments? Check all that apply",
                    "options": {
                         "Yes, in person": 0.72,
                         "Yes, over phone": 0.545,
                         "Yes, online": 0.545,
                         "No": 0.79,
                         "Not Sure": 0.5
                    },
                    "is_checkbox": false,
                    "is_multiple": true,
                    'formula': "considermultiple"
               },
               {
                    "question": "How many credit/debit card transactions does your company process annually?",
                    "options": {
                         "None": 0.79,
                         "less than 6 mil": 0.66,
                         "More than 6 mill": 0.5
                    },
                    "is_checkbox": false
               },
               {
                    "question": "How many personally - identifiable records do you create, collect, store, manage, or process in a year?",
                    "options": {
                         "Less than 1,000": 0.72,
                         "1,000 - 5,000": 0.66,
                         "5,000 - 20,000": 0.6,
                         "20,000 - 100,000": 0.545,
                         "100,000 - 1 mill": 0.5,
                         "1 mill - 5 mill": 0.5,
                         "more than 5 mill": 0.45
                    },
                    "is_checkbox": false
               },
               {
                    "question": "How many personally - identifiable records do you have stored?",
                    "options": {
                         "Less than 1,000": 0.79,
                         "1,000 - 5,000": 0.66,
                         "5,000 - 20,000": 0.6,
                         "20,000 - 100,000": 0.545,
                         "100,000 - 1 mill": 0.5,
                         "1 mill - 5 mill": 0.5,
                         "more than 5 mill": 0.45
                    },
                    "is_checkbox": false
               },
               {
                    "question": "Is your Organization required to be compliant with any of the following? Choose all that apply",
                    "options": {
                         // "Answered": 0,
                         "PCI": 0.6,
                         "GLBA": 0.72,
                         "C-TPAT": 0.72,
                         "COPPA": 0.6,
                         "FACTA": 0.6,
                         "FISMA": 0.6,
                         "NERC": 0.6,
                         "21 CFR Part 11": 0.6,
                         "HIPAA": 0.5,
                         "Mass. Data Prot": 0.6,
                         "Nevada PI Data": 0.6,
                         "GDPR": 0.5,
                         "None of the above": 0.79,
                         "Not Sure": 0.5,
                         "Other": 0.6
                    },
                    "is_checkbox": false,
                    "is_multiple": true,
                    "formula": "easy"

               },
               {
                    "question": "Do any standards, regulations, or laws in question above affect your Organization via information shared by customers?",
                    "options": {
                         "Yes": 0.5,
                         "No": 0.79,
                         "Not Sure": 0.5
                    },
                    "is_checkbox": true
               },
               {
                    "question": "Does your company utilize contractual or other risk transfer methods to minimize financial exposures?",

                    "options": {
                         "Yes": 0.79,
                         "No": 0.6,
                         "Not Sure": 0.5
                    },
                    "is_checkbox": true
               },
               {
                    "question": "What insurance products are currently being utilized by your organization? Check all that apply",
                    "options": {
                         "Cyber Liability": 0.79,
                         "Privacy Notification": 0.79,
                         "Crisis Mgmt.": 0.79,
                         "E & O": 0.79,
                         "D & O": 0.79,
                         "Media Liability": 0.79,
                         "Professional Liab": 0.79,
                         "Business Interrupt": 0.79,
                         "Theft Loss": 0.79,
                         "Comm Loss": 0.79,
                         "Threat Exp": 0.79,
                         "Vandalism Exp": 0.79,
                         "Reward Exp": 0.79,
                         "Not Sure": 0.6,
                    },
                    "is_checkbox": false,
                    'formula': 'complicated',
                    "is_multiple": true
               },
               {
                    "question": "Has the Organization applied for “Cyber Insurance” in the last 2 years?",
                    "options": {
                         "Yes": 0.79,
                         "No": 0.6,
                         "Not Sure": 0.5
                    },
                    "is_checkbox": true
               },
               {
                    "question": "Has the Organization ever been denied “Cyber Insurance” coverage?",
                    "options": {
                         "Yes": 0.6,
                         "No": 0.79,
                         "Not Sure": 0.5
                    },
                    "is_checkbox": true
               },
               {
                    "question": "Does your Organization develop, produce or sell its own branded products?",
                    "options": {
                         "Yes": 0.6,
                         "No": 0.79,
                         "Not Sure": 0.5
                    },
                    "is_checkbox": true
               },
               {
                    "question": "Are your Organization's digital assets valued as assets on the balance sheet?",
                    "options": {
                         "Yes": 0.790,
                         "No": 0.600,
                         "Not Sure": 0.600
                    },
                    "is_checkbox": true
               }
          ],
          "CSS": [{
               "question": "How many days will your organization require to become 100% compliant with the High Impact compliance regulations and standards identified in the Compliance Impact Score?",
               "options": {
                    "0 days": 0.1,
                    "1 to 7 days": 0.075,
                    "8 to 21 days": 0,
                    "22 to 30 days": -0.005,
                    "Between 1 month (31 days) and 6 months (180 days)": -0.0017,
                    "181 days or more - more than 6 months": -0.31
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to mitigate Risk Gaps identified in the Governance Risk Score?",
               "options": {
                    "0 days": 0.1,
                    "1 to 7 days": 0.075,
                    "8 to 21 days": 0,
                    "22 to 30 days": -0.005,
                    "Between 1 month (31 days) and 6 months (180 days)": -0.0017,
                    "181 days or more - more than 6 months": -0.31
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to designate a Data Protection or Chief Information Security Officer?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to complete training on established data governance standards and policies?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to complete data classification and data flow mapping?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to implement a data retention / record keeping program?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to adopt a cohesive data risk assessment program?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to establish data tracking / monitoring systems for cross-border data transfers and processing?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to adopt a controller data protection / risk management program?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to meet its data transparency / communication obligations for individual customers?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to adopt a customer data portability program?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.03,
                    "61 to 180 days": -0.05,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to adopt a customer revocation of consent i.e. opt-in / opt-out program?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to adopt a 72-hour breach notification / response program?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to adopt a vendor / data processor risk management program?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          },
          {
               "question": "How many days will your organization require to establish a communication framework with regulatory authorities?",
               "options": {
                    "0 days": 0.03,
                    "1 to 7 days": 0.02,
                    "8 to 21 days": 0,
                    "22 to 60 days": -0.02,
                    "61 to 180 days": -0.03,
                    "181 days or more - more than 6 months": -0.0525
               },
               "is_checkbox": false
          }
          ]
     }
}

