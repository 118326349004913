import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() is_grs: boolean;
  @Input() navigation: any
  @Input() total_questions: any
  @Input() per_page: any
  steps: any = []
  @Input() current_step: any

  @Output() goToThatStepNumber = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }



  ngOnChanges() {

    if (this.total_questions) {

      let totalPages = Math.ceil(this.total_questions / this.per_page);
      if (this.is_grs) {
        totalPages = totalPages + 1;
      }
      this.steps = []
      for (let i = 0; i < totalPages; i++) {
        this.steps.push(i)
      }
    }
  }

  goToThatStep(step) {
    this.goToThatStepNumber.emit(step)
  }
}
