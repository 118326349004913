import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  username: any = window.localStorage.getItem('user_name') || "username"
  constructor() { }
  @Input() loginInfo: any = {}
  ngOnInit() {
  }

  logout() {
    window.localStorage.clear();
    window.location.href = 'login'
  }
}
