import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Http, Headers } from '@angular/http'
import { Injectable } from '@angular/core';
import { CONFIG } from './../config/config';
// import * as firebase from "firebase/app";

// import { AngularFireAuth, } from 'angularfire2/auth';
@Injectable()
export class GrsService {

    total_questions: any = []
    headers: any
    constructor(private http: HttpClient,
        private _http: Http,
        // private afAuth: AngularFireAuth,
    ) {
        this.headers = new Headers({
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': CONFIG.jwt()
        });
    }



    global_questions_array: any; // First declare this in the global angular scope.

    // using //
    async getQuestionType(page: any = 0, per_page: any = 7, type = null, id = null) {

        if (this.global_questions_array) {

            let return_obj: any = {};

            if (id) {
                // Then run a for loop to get that question only. 
                for (let key in this.global_questions_array) {
                    let one_section_array = this.global_questions_array[key]
                    for (let i of one_section_array) {
                        if (i.question.id == id) {
                            return_obj = {
                                success: true,
                                questions: i
                            }
                            break;
                        }
                    }
                }
                return return_obj
            }

            if (type) {

                let existingQuestions = this.global_questions_array[type];


                let filtered_not_conditional = [];
                if (existingQuestions && existingQuestions.length) {
                    for (let obj of existingQuestions) {
                        if (!obj.question.is_conditional) {
                            filtered_not_conditional.push(obj)
                        }
                    }
                }


                return_obj = filtered_not_conditional.slice((page * per_page), (page * per_page) + per_page);
                return_obj = {
                    success: true,
                    questions: return_obj,
                    questions_count: return_obj.length,
                    total_count: filtered_not_conditional.length
                }

            }

            return return_obj;
        }


        let url;

        url = `${CONFIG.API_URL}/questions?page=${page}&per_page=${per_page}`;

        if (id) {
            url += `&id=${id}`
        }
        if (type) {
            url += `&type=${type}`
        }

        let res = await this._http.get(url, { headers: this.headers }).toPromise();
        return res.json();
    }

    // async updateAnswer(data) {
    //     let token = await firebase.auth().currentUser.getIdToken()
    //     let headers = new Headers({
    //         'X-AUTH-TOKEN': `${token}`,
    //         'Content-Type': 'application/json'
    //     })

    //     let url = `${CONFIG.API_URL}/composite_score/capture/user/answer`
    //     return await this._http.post(url, data, { headers: headers }).toPromise();
    // }


    // async getAllanswers() {

    //     try {

    //         let token = await firebase.auth().currentUser.getIdToken()

    //         let headers = new Headers({
    //             'X-AUTH-TOKEN': `${token}`,
    //             'Content-Type': 'application/json'
    //         })

    //         let url = `${CONFIG.API_URL}/composite_score/user/answer/to/question`
    //         return await this._http.get(url, { headers: headers }).toPromise()

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    async getConfigDetails() {

        // let token = await firebase.auth().currentUser.getIdToken()

        // let headers = new Headers({
        //     'X-AUTH-TOKEN': `${token}`,
        //     'Content-Type': 'application/json'
        // })

        let url = `${CONFIG.API_URL}/config/get`
        return await this._http.get(url, { headers: this.headers }).toPromise();
    }


    async postConfig(data) {

        // let token = await firebase.auth().currentUser.getIdToken()

        // let headers = new Headers({
        //     'X-AUTH-TOKEN': `${token}`,
        //     'Content-Type': 'application/json'
        // })

        let url = `${CONFIG.API_URL}/config/set`
        return await this._http.post(url, data, { headers: this.headers }).toPromise()
    }

    // async updateAnswerOfUserQuestion(data) {
    //     let token = await firebase.auth().currentUser.getIdToken()

    //     let headers = new Headers({
    //         'X-AUTH-TOKEN': `${token}`,
    //         'Content-Type': 'application/json'
    //     })

    //     let url = `${CONFIG.API_URL}/composite_score/update/answer/of/user/question`

    //     return await this._http.post(url, data, { headers: headers }).toPromise()
    // }



    // using //
    async getAllTotalQuestions() {
        try {
            // change the new endpoint
            let url = `${CONFIG.API_URL}/questions?all_formatted_response=YES`;
            let res: any = await this._http.get(url, { headers: this.headers }).toPromise();
            res = res.json();
            if (res.success) {
                this.global_questions_array = res.questions;
                return res.questions;
            }


        } catch (error) {
            console.log(error);
        }
    }

    // using //
    async setAnswerOfUserQuestion(data) {
        let url = `${CONFIG.API_URL}/answer/question`
        return this._http.post(url, data, { headers: this.headers }).toPromise()
    }
    // using //
    async updateAnswerOfUserQuestion(data) {
        let url = `${CONFIG.API_URL}/answer/update`
        return this._http.post(url, data, { headers: this.headers }).toPromise()
    }





    // async function serviceToGetAllQuestions() {
    //     // This is a new service that you have to write. 
    //     let options = {
    //         method: "GET",
    //         uri: "http://cloudcover-api-v2.o.fountane.xyz./composite_score/load/all/questions",
    //         json: true
    //     }
    //     let questions = await this.request(options);

    //     return questions;
    // }

    // async function componentHandler() {
    //     let questions = await serviceToGetAllQuestions();
    //     this.global_questions_array = questions;
    // }

    // async function main() {
    //     // The ngOnInit function basically

    //     await componentHandler();

    //     console.log(this.global_questions_array);
    // }

    // How to get a specific question or a range of questions. This code snippet can be put in the existing get questions/ get question by id api call.


    simplifiedGetQuestion(type, page, per_page, id, allQuestionsArray) {
        // The additional argument you should be passing is allQuestions array. 

        let return_obj = {};

        let existingQuestions = allQuestionsArray[type];

        if (id) {
            // Then run a for loop to get that question only. 
            for (let i of existingQuestions) {
                if (i.question.id == id) {
                    return_obj = {
                        success: true,
                        question: i
                    }
                }
            }
        } else {
            // This means pagination comes into place.

            return_obj = existingQuestions.slice((page * per_page), (page * per_page) + per_page);
            return_obj = {
                success: true,
                questions: return_obj
            }
        }
    }



}