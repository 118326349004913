import { Component, OnInit, NgZone } from '@angular/core';
import { GrsService } from "./../../services/grs.service";
import { AirtableService } from "./../../services/airtable.service";
import { AuthService } from "./../../services/auth.service";
import { CONFIG } from "../../config/config";

@Component({
  selector: 'app-detail-report',
  templateUrl: './detail-report.component.html',
  styleUrls: ['./detail-report.component.scss']
})
export class DetailReportComponent implements OnInit {
  legal_domicile_score: any = 0;
  country_composite_regulation_score: any = 0;
  country_class_of_trade_risk_score: any = 0;
  class_of_trade_composite_risk_score: any = 0;
  crp_data: any;
  score_Current_Methodology: any;
  narrative_bullet_points: any = {
    grs: {
      NC01: "Impact of compliance and regulatory requirements",
      NC02: "Payment methods and amount of personal information collected and stored ect/Store personal Information",
      NG01: "Information Security Policy documentation, communication and training",
      NG02: "Incident management process",
      NG03: "Encryption policy",
      NG04: "Policies on destruction of paper and electronic information",
      NG05: "Assigning, changing and auditing user accounts",
      NG06: "Unauthorized software",
      NG07: "Utilization of background checks",
      NG08: "Disaster Recovery plans",
      NG09: "Ongoing Information Security Risk Assessments",
      NG10: "Outsourced services and vendor risk",
      NG11: "Cyber Risk Transfer",
    },
    data_governance: {
      NC01: "Impact of compliance and regulatory requirements",
      NC02: "Payment methods and amount of personal information collected and stored ect/Store personal Information",
      NG01: "Information Security Policy documentation, communication and training",
      NG02: "Incident management process",
      NG03: "Encryption policy",
      NG04: "Policies on destruction of paper and electronic information",
      NG05: "Assigning, changing and auditing user accounts",
      NG06: "Unauthorized software",
      NG07: "Utilization of background checks",
      NG08: "Disaster Recovery plans",
      NG09: "Ongoing Information Security Risk Assessments",
      NG10: "Outsourced services and vendor risk",
      NG11: "Cyber Risk Transfer",
    },
    physical_security: {
      NP01: "Physical security policies and assessment",
      NP02: "Access of vendors and visitors to the organization facilities ",
      NP03: "Data center access control ",
      NP04: "Back- up power and environmental controls ",
      NP05: "3rd Party data center or colocation utilization ",
    },
    external_security: {
      NE01: "External IP addresses utilized ",
      NE02: "Monitoring of traffic between internal and external networks and intrusion detection  ",
      NE03: "Utilization of E- Commerce ",
      NE04: "Remote access and authentication ",
      NE05: "Cloud services utilization and incident communication ",
      NE06: "Encryption for data transfers ",
      NE07: "External vulnerability scanning  ",
      NE08: "Penetration testing ",
    },
    internal_security: {
      NT01: "Internet circuits, firewalls and devices utilized",
      NT02: "Servers, workstations and laptops utilized",
      NT03: "Company owned mobile devices",
      NT04: "Personally owned mobile devices",
      NT05: "Network segmentation, management network, and DMZ’s",
      NT06: "Network based intrusion detection",
      NT07: "Network architecture and vendor network support",
      NT08: "Patch management process and documentation",
      NT09: "Vulnerability scans and resolution of issues identified",
      NT10: "Network  and server change process",
      NT11: "Laptop encryption",
      NT12: "Mobile device management",
      NT13: "Removable media",
      NT14: "Involvement in software development",
      NT15: "Hardened server and network standards",
      NT16: "Anti - Virus software and updates",
      NT17: "Network logging and monitoring standards",
      NT18: "Wireless network segmentation, firewalls and auditing",
      NT19: "User administrative rights",
    },

  }
  allAnswers: any
  bullet_points_set: boolean = false
  user_narative_bullet_points: any = {
    grs: {},
    data_governance: {},
    physical_security: {},
    internal_security: {},
    external_security: {}
  }
  detail_report_bullet_points: any = {
    impactScores: {
      Legal_Domicile_Score: 'Legal Domicile Score',
      Legal_Domicile_Desc: 'The Legal Domicile Score is a risk coefficient that measures the Geographic Risk, i.e., the Country and Region Risk values, combined with the values associated with risk for each of the four UNTAD E-Transactions Laws specific for that Country. Legal Domicile Score considers how the demographic nuisances of the Region and Country can influence cyber risk.  Subsequently, the Legal Domicile Score is it used during the underwriting process for risk transfer products and is one of the key variables for determining risk transfer premiums. While 154 countries (79 per cent) have enacted cybercrime legislation, the pattern varies by region: Europe has the highest adoption rate (93 per cent) and Asia and the Pacific the lowest (55 per cent).  It is important to note that 13% of the have no regulation at all or only a few, e.g., 1 or 2 of the UNTAD E-transaction laws.',
      Country_Composite_Regulation_Score: 'Country(s) Composite Regulation Score',
      Country_Composite_Regulation_Desc: 'Country Composite Regulation Score is a risk coefficient that measures the Regulatory Risk of the four UNTAD E-Transactions Laws specific for that Country across all 27 Classes of Trade. This is composite score that averages the entire regulatory environment risk for a company conducting business in a specific Country’s.  The Country Composite Regulation Score is used for comparison analysis and to create weighted averages of Regulatory Risk between Countries if a company conducts business in more than one country.',
      Country_Class_of_Trade_Risk_Score: 'Country(s) Class of Trade Risk Score',
      Country_Class_of_Trade_Risk_Desc: `Country Class of Trade Risk Score is a unique risk coefficient that measures not only the Regulatory Risk of the four UNTAD E-Transactions Laws specific for that Country for a specific Class of Trade but also the Insurance Risk, i.e., the risk of a potential claim associated with a specific Class of Trade in that country. Subsequently, the Country Class of Trade Risk Score is a heavily weighted score used during the underwriting process for risk transfer products and is one of the key variables for determining risk transfer premiums.
                                        The strength and potential of enforcement of a country’s cyber regulations is directly linked to the cost of providing cyber insurance.Moreover, there is a modest variance in the cost of provided cyber insurance between the various Classes of Trade, i.e., some Classes of Trade have an increased likelihood of a potential cyber breach and therefore warrant higher premiums.Countries with complex regulations that define the penalties and renumeration expenses associated with a cyber breach have higher costs associated with risk transfer products ensuring a particular company.
                                        `,
      Class_of_Trade_Composite_Risk_Score: `Class of Trade Composite Risk Score `,
      Class_of_Trade_Composite_Risk_Desc: `Class of Trade Composite Risk Score is a risk coefficient that is an average of the Regulatory Risk of the four UNTAD E-Transactions Laws specific for that Country for a specific Class of Trade but also the Insurance Risk, i.e., the risk of a potential claim associated with all Classes of Trade in that country. The Class of Trade Composite Risk Score is used for comparison analysis and to create weighted averages of Insurance Risk between Countries if a company conducts business in more than one country.`
    },
    governanceScores: {
      CSR_Governance_Score: 'CSR Governance Score',
      CSR_Governance_Desc: 'Governance Risk Score indicates that several critical safeguards and controls may be in place to protect the organization’s digital assets, however, additional critical controls and safeguards require implementation of a proactive strategy. Additionally, the following action steps are strongly recommended:  1) The primary goal is to evaluate and review current vulnerabilities to sensitive information including personnel private information and have a thorough breach response strategy and comprehensive remediation plan in place. 2) Organizations that have a Compliance Impact Score in this range must employ a Chief Information Security Officer (CISO) or a Data Protection Officer (DPO) with an extensive proactive management strategy to develop internal controls and procedures that promote adherence to applicable regulations. 3) The costs are minor compared to the risk of non-compliance even though the implementation of an effective compliance program may require a significant commitment of time and resources.',
      CSR_Governance_Para1: 'The Governance Risk Score (GRS) indicates the degree of critical governance safeguards and controls that are in place to protect the organization’s digital assets. Moreover, the GRS assesses an organization’s vulnerabilities, compliance, and its remediation strategies in the event of a data breach. Compliance is the documentation of those actions. While you may be protecting your systems, networks, and software, you cannot prove control effectiveness without documentation.',
      CSR_Governance_Para2: 'Documenting your continuous monitoring and response activities provides your internal or external auditors with the information necessary to prove governance. Moreover, the documentation process eases conversations with business leadership and enables the Board of Directors to better review cybersecurity risk.',
      CSR_Governance_Para3: 'The CSR Impact Score metric is a coefficient derived from multiplicative function from the four following measurements. These four measures are the foundation of documenting your cyber security strategies.  They are:',
    },
    cyberSecurityScores: {},
  }
  loading: boolean = false

  GRS_QUS: any = CONFIG.QUESTIONS.GRS;

  risk_texts: any = {
    grs: '',
    css: '',
    cis: "",
    composite_grs_score: ""
  }
  set_classnames = {
    grs: '',
    css: '',
    cis: "",
    composite_grs_score: ""
  }

  employee_factor: any = {
    "0": "1.000",
    "1 - 49": "0.990",
    "50 - 199": "0.980",
    "200 - 499": "0.970",
    "500 - 999": "0.960",
    "1,000 - 4, 999": "0.950",
    "5,000 - 9, 999": "0.940",
    "10,000 - 24, 999": "0.925",
    "25,000 or more": "0.915"
  }
  employee_factor_number: any;

  footprint: any;
  scores: any = {
    css: 0,
    grs: 0,
    cis: 0,
    composite_grs_score: 0
  }

  getScoresDetails: any = {
    data_governance: "",
    physical_security: "",
    internal_security: "",
    external_security: ""
  };
  scoresGraphData: any = {}
  countryCount: number;
  countriesData: any = []


  constructor(
    private grsService: GrsService,
    private authService: AuthService,
  ) {
    // this.scoresGraphData = JSON.parse(localStorage.getItem('scoresGraphData'))
  }
  ngOnInit() {
    this.bullet_points_set = true;
    // this.risk_texts = JSON.parse(localStorage.getItem('risk_texts'));
    this.getUserInfo();
  }

  async getUserInfo() {
    try {
      let res = await this.authService.profile();
      let response = res.json();
      // console.log(response, "FROM DETAILS");
      if (response && response.success && response.user) {

        this.countryCount = response.user ? response.user['countryIds'].length : 0;
        this.crp_data = response.user;

        // it should be here - dont move it//
        this.getCountriesData();

        this.footprint = this.crp_data.global_footprint;

        this.crp_data.cis_score = parseFloat(this.crp_data.cis_score);
        this.crp_data.css_score = parseFloat(this.crp_data.css_score);
        this.crp_data.grs_score = parseFloat(this.crp_data.grs_score);
        this.country_composite_regulation_score = parseFloat(this.crp_data.country_composite_regulation_score).toFixed(3);
        this.legal_domicile_score = parseFloat(this.crp_data.legal_domicile_score).toFixed(3);
        this.class_of_trade_composite_risk_score = parseFloat(this.crp_data.class_of_trade_composite_risk_score).toFixed(3);
        this.country_class_of_trade_risk_score = parseFloat(this.crp_data.country_class_of_trade_risk_score).toFixed(3);

        if (this.crp_data.cis_score) {
          this.scores = {
            css: this.crp_data.css_score_to_show == '0' ? this.crp_data.css_score_to_show : Number(this.crp_data.css_score_to_show).toFixed(3),
            cis: this.crp_data.cis_score == '0' ? this.crp_data.cis_score : Number(this.crp_data.cis_score).toFixed(3),
            grs: this.crp_data.grs_score == '0' ? this.crp_data.grs_score : Number(this.crp_data.grs_score).toFixed(3),
          }
          // console.log("SCORE", this.scores);

          // if composite_grs exits priory should be for this
          if (this.crp_data.composite_grs_score) {

            let data_governance = this.crp_data.data_governance_score
            let physical_security = this.crp_data.physical_security_score
            let internal_security = this.crp_data.internal_security_score
            let external_security = this.crp_data.external_security_score

            this.getScoresDetails = this.crp_data;
            this.getScoresDetails.data_governance = Number(this.crp_data.data_governance_score).toFixed(3)
            this.getScoresDetails.physical_security = (Number(this.crp_data.physical_security_score)).toFixed(3)
            this.getScoresDetails.internal_security = (Number(this.crp_data.internal_security_score)).toFixed(3)
            this.getScoresDetails.external_security = (Number(this.crp_data.external_security_score)).toFixed(3)

            if (data_governance && physical_security && internal_security && external_security) {
              if (Number(this.crp_data.composite_grs_score) > 0) {
                this.scores.composite_grs_score = Number(this.crp_data.composite_grs_score).toFixed(3)
              }
            }
          }
        }
        await this.updateScoreText();
        await this.setUserNarratives()
        // console.log("SCORE", this.scores);
        // console.log("SCORE ADD ", this.scores.composite_grs_score, this.scores.cis, this.scoresGraphData.css.score);
        // this.score_Current_Methodology = (parseFloat(this.scores.composite_grs_score) + parseFloat(this.scores.cis) + parseFloat(this.scoresGraphData.css.score)) / this.countryCount
        // console.log(" this.score_Current_Methodology", this.score_Current_Methodology);
        // console.log("RISKTAKES",this.risk_texts);
      }


    } catch (error) {
      console.log(error);
    }
  }

  async getCountriesData() {
    try {
      let res = await this.authService.commonData()
      let response = res.json()
      if (response.success) {
        this.countriesData = response.data;
        this.crp_data.selectedCountries = "";
        if (this.countriesData.countries && this.crp_data.countryIds) {
          let countries_data = this.countriesData.countries.filter(country => this.crp_data.countryIds.includes(country.id));
          if (countries_data && countries_data.length) {
            let country_names = countries_data.map(ele => ele.country_name);
            this.crp_data.selectedCountries = country_names.join(", ");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async setUserNarratives() {
    this.loading = true
    try {
      let res: any = await this.grsService.getAllTotalQuestions();
      // console.log("Response of user answers");
      // console.log(res);

      if (res) {
        let _th = this;
        let options, tags, question_type, obj, o;

        // i'm adding NC02 as well because we need to show always
        this.user_narative_bullet_points.data_governance["NC02"] = this.narrative_bullet_points.data_governance["NC02"];

        for (question_type in res) {
          for (o of res[question_type]) {
            if (o && o.user_answers) {
              options = o.user_answers;
              for (obj of options) {
                if (obj.tag) {
                  tags = obj.tag;
                  if (tags && question_type) {
                    _th.user_narative_bullet_points[question_type][tags] = _th.narrative_bullet_points[question_type][tags];
                  }
                }
              }
            }

            _th.bullet_points_set = true
            // console.log('user narrative bullet points are', _th.bullet_points_set);
            // console.log(_th.user_narative_bullet_points);

            for (let k in _th.narrative_bullet_points) {
              _th.user_narative_bullet_points[k + "_arry"] = Object.values(_th.user_narative_bullet_points[k])
            }
          }
        }

      }
    } catch (error) {
      console.log("error");
      console.log(error);
    }
    this.loading = false
  }

  updateScoreText() {

    let set_classnames = {
      grs: '',
      css: '',
      cis: "",
      composite_grs_score: ""
    }

    let classNames = ['major_risk', 'cautionary_risk', 'moderate_risk', 'minor_risk', 'acceptable_risk'];
    let new_text_colors = ["cmpst-deep-red", 'cmpst-red', 'cmpst-red-orange', 'cmpst-orange', 'cmpst-yellow', 'cmpst-green', 'cmpst-blue', 'cmpst-ultramarine']

    for (let i in this.scores) {
      if (this.scores[i] == 0) {
        set_classnames[i] = "major_risk zero_score"
      } else if (this.scores[i] <= 0.649) {
        set_classnames[i] = classNames[0]
        this.risk_texts[i] = 'Major Risk';
      } else if (this.scores[i] <= 0.673) {
        set_classnames[i] = classNames[1]
        this.risk_texts[i] = 'Cautionary Risk';
      } else if (this.scores[i] <= 0.701) {
        set_classnames[i] = classNames[2]
        this.risk_texts[i] = 'Moderate Risk';
      } else if (this.scores[i] <= 0.745) {
        set_classnames[i] = classNames[3]
        this.risk_texts[i] = 'Minor Risk';
      } else {
        set_classnames[i] = classNames[4]
        this.risk_texts[i] = 'Acceptable Risk';
      }
    }
    for (let i in this.scores) {
      if (i === 'composite_grs_score') {
        if (this.scores[i] == 0) {
          set_classnames[i] = "major_risk zero_score"
        } else if (this.scores[i] > 0 && this.scores[i] <= 0.649) {
          this.risk_texts[i] = 'Major Risk';
        } else if (this.scores[i] >= 0.650 && this.scores[i] <= 0.673) {
          this.risk_texts[i] = 'Cautionary Risk';
        } else if (this.scores[i] >= 0.674 && this.scores[i] <= 0.701) {
          this.risk_texts[i] = 'Moderate Risk';
        } else if (this.scores[i] >= 0.702 && this.scores[i] <= 0.745) {
          this.risk_texts[i] = 'Minor Risk';
        } else {
          this.risk_texts[i] = 'Acceptable Risk';
        }

        if (this.scores[i] > 0 && this.scores[i] <= 0.250) {
          set_classnames[i] = new_text_colors[0]
        }

        if (this.scores[i] > 0.250 && this.scores[i] <= 0.400) {
          set_classnames[i] = new_text_colors[1]
        }

        if (this.scores[i] > 0.400 && this.scores[i] <= 0.500) {
          set_classnames[i] = new_text_colors[2]
        }
        if (this.scores[i] > 0.500 && this.scores[i] <= 0.600) {
          set_classnames[i] = new_text_colors[3]
        }
        if (this.scores[i] > 0.600 && this.scores[i] <= 0.700) {
          set_classnames[i] = new_text_colors[4]
        }

        if (this.scores[i] > 0.700 && this.scores[i] <= 0.800) {
          set_classnames[i] = new_text_colors[5]
        }
        if (this.scores[i] > 0.800 && this.scores[i] <= 0.900) {
          set_classnames[i] = new_text_colors[6]
        }
        if (this.scores[i] > 0.900 && this.scores[i] <= 0.999) {
          set_classnames[i] = new_text_colors[7]
        }
      }
    }

    this.set_classnames = set_classnames;
    // console.log({ set_classnames, risk: this.risk_texts });


    let Major_composite_score = 0;
    if (this.scores.composite_grs_score) {
      Major_composite_score = this.scores.composite_grs_score
    } else {
      Major_composite_score = 0
      set_classnames["composite_grs_score"] = "major_risk zero_score"
    }

    this.scoresGraphData = {
      cis: {
        data: {
          series: [
            {
              value: 100 - this.scores.cis * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: this.scores.cis * 100,
              className: `${set_classnames.cis} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.cis,
        heading: "Compliance Impact Score",
        score: this.scores.cis,
        data_type: 'cis'
      },
      grs: {
        data: {
          series: [
            {
              value: 100 - this.scores.grs * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: this.scores.grs * 100,
              className: ` ${set_classnames.grs} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.grs,
        heading: "Governance Risk Score",
        score: this.scores.grs,
        data_type: 'grs'
      },
      composite_grs: {
        data: {
          series: [
            {
              value: 100 - Major_composite_score * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: Major_composite_score * 100,
              className: ` ${set_classnames.composite_grs_score} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.composite_grs_score,
        heading: "Governance Risk Score",
        score: Major_composite_score,
        data_type: 'composite_grs'
      },
      css: {
        data: {
          series: [
            {
              value: 100 - this.scores.css * 100,
              className: 'ScoreChartUnfilled ct-series-a',
            },
            {
              value: this.scores.css * 100,
              className: `${set_classnames.css} ScoreChartfilled ct-series-b`
            }
          ]
        },
        options: {
          donut: true,
          donutWidth: 13,
          showLabel: false,
          startAngle: 0,
          total: 100,
          width: 180,
          height: 180
        },
        type: 'Pie',
        className: set_classnames.css,
        heading: "CyberSafety Score",
        score: this.scores.css,
        data_type: 'css'
      },
    }

    // this.score_Current_Methodology = (parseFloat(this.scores.composite_grs_score) + parseFloat(this.scores.cis) + parseFloat(this.scoresGraphData.css.score)) / this.countryCount

  }

  print: boolean = false
  printing() {
    this.print = true
    setTimeout(() => {
      window.print()
      this.print = false
    }, 300);
  }


}
