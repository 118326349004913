import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ocq-dropdown',
  templateUrl: './ocq-dropdown.component.html',
  styleUrls: ['./ocq-dropdown.component.scss']
})
export class OcqDropdownComponent implements OnInit {
  @Input() question_data: any
  @Input() index: any;
  @Output() getNewQuestion: any = new EventEmitter<any>();
  @Output() postAnswer: any = new EventEmitter<any>();
  @Input() specific_no_days : any ;
  constructor() { } 

  ngOnInit() {}

  saveGrsValues(e, i, v, k) {
    if (e) {
      e.preventDefault();
    }
    
    this.question_data.answer = v.option;
    let obj = {
      id: this.question_data.question.id,
      options_array: [v.id],
      question_type : this.question_data.question.question_type,
      user_answers  : this.question_data.user_answers,
      question_data : this.question_data,
      is_text_input_allowed : v.is_text_input_allowed
    }
    this.postAnswer.emit(obj)
  }

}
