import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component'
import { HomeComponent } from './components/home/home.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { CrpComponent } from './components/crp/crp.component';
import { BriefReportComponent } from './components/brief-report/brief-report.component';
import { AuthGuardService } from "./gaurds/auth.gaurd";
import { GrsReportComponent } from './components/grs-report/grs-report.component';
import { DetailReportComponent } from './components/detail-report/detail-report.component';
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component"

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent
  },
  {
    path: 'reset-password/:uniqId',
    component: ResetPasswordComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'report',
    component: GrsReportComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'brief-report',
    component: BriefReportComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'details-report',
    component: DetailReportComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'crp/:status',
    component: CrpComponent,
    pathMatch: 'full',
    canActivate: [AuthGuardService]
  },

  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
