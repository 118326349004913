import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ocq',
  templateUrl: './ocq.component.html',
  styleUrls: ['./ocq.component.scss']
})
export class OcqComponent implements OnInit {
  @Input() question_data: any
  @Input() index
  @Output() getNewQuestion: any = new EventEmitter<any>();
  @Output() postAnswer: any = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  optionSelected(e, option) {

    // if (option.next_question) {
    //   this.getNewQuestion.emit(option)
    // // }
    // if (this.question_data.record) {
    //   option.record = this.question_data.record
    // }

    // let post_obj: any = {
    //   question: this.question_data.question,
    //   options: [
    //     option
    //   ],
    //   all_options: this.question_data.options
    // };

    // if (this.question_data.user_answers) {
    //   this.question_data.user_answers.push(obj)
    // }

    let obj = {
      id: this.question_data.question.id,
      options_array: [option.id],
      question_type: this.question_data.question.question_type,
      user_answers: this.question_data.user_answers,
      question_data: this.question_data,
      option_id: option.id,
    }

    this.postAnswer.emit(obj);


  }



}
